import { CI } from '../../../../models/CI';
import { useCustomModal } from '../../../modals/custom-message-modal';
import AddCIForm from '../ci/mapCategoryForm';
import { mockEditCategoryData } from '../cimHelper';

type UseEditCIModal = {
  showEditCIModal: (params: {
    onSuccess: () => void;
    ciData: CI;
  }) => void;
};

const useEditCI = (): UseEditCIModal => {
  const { showModal, hideModal } = useCustomModal();

  const showEditCIModal = ({
    onSuccess,
    ciData,
  }: {
    onSuccess: () => void;
    ciData: CI;
}): void => {
    
    showModal(AddCIForm, {
      hideModal,
      onRefersh: onSuccess,
      defaultData: mockEditCategoryData(ciData),
    });
  };

  return { showEditCIModal };
};

export default useEditCI;
