import React, {useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle} from 'react';
import '../Panel.scss'
import WebViewerContext from "../../../contexts/webviewer-context";
import RedactionSearchMultiSelect from "./RedactionSearchMultiSelect";
import PageRangeInput from "../../PageRangeInput";
import SearchStatus from "./SearchStatus";
import RedactionSearchResultsContainer, {createSearchResultHighlights} from "./RedactionSearchResultsContainer";
import {useCustomModal} from "../../../pages/modals/custom-message-modal";
import ChangeMarginsModal from "../ChangeMarginsModal";
import {Checkbox, Menu, MenuItem, Tooltip} from "@mui/material";
import {executeImageSearch, loadNLPRedactionSearch} from "../documentSearch";
import {StyledCloseIconUploader} from "../../close-button";
import Dropzone from "react-dropzone-uploader";
import {FileState} from "../../../models/FileState";
import {showSnackbar} from "../../../redux/snackbar";
import FilterFunctionContext from "../../../contexts/filter-function-context";
import {roleMultiSelectRenderValue} from "../../../services/utils";
import BulkSearchTerms from "../BulkSearchTerms";
import {SearchCancellationProvider, useSearchCancellation} from "../../../contexts/search-cancellation-context";
import SelectedMarkStyleContext from "../../../contexts/selected-mark-style-context";

export const SearchPanel =  forwardRef((props: {redactionSearchPatterns: {label: string, type: string, regex: RegExp}[], selectedTab: any}, ref) => {
    const { setInstance,instance } = useContext(WebViewerContext);
    const {filterFunction} = useContext(FilterFunctionContext);
    const { showModal, hideModal } = useCustomModal();
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageRange, setPageRange] = useState<(number|undefined)[]|undefined>(undefined);
    const [wholeWord, setWholeWord] = useState<boolean>(true);
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [isProcessingRedactionResults, setIsProcessingRedactionResults] = useState<boolean>(false);
    const [searchStatus, setSearchStatus] = useState<string>(SearchStatus.SEARCH_NOT_INITIATED);
    const [searchTerms, setSearchTerms] = useState<{ label: any; value: any; type: string; }[]>();
    const cancellationTokenRef = useSearchCancellation()
    const { selectedMarkStyle, setSelectedMarkStyle } = useContext(SelectedMarkStyleContext)

    const loadSearchResultHighlights = () => {
        return instance.Core.annotationManager.getAnnotationsList().filter((annotation: any) => annotation.getCustomData('isSearchResult') === 'true');
    }

    useImperativeHandle(ref, () => ({
        handleNlpJson: handleNlpJson,
      }));

    function pageRangeOnClear() {
        setPageRange(undefined)
    }

    useEffect(() => {
        if (searchStatus === SearchStatus['SEARCH_DONE']) {
            instance.Core.documentViewer.trigger('processStarted')
            setTimeout(() => {
                const pageSearchResults = instance.Core.documentViewer.getPageSearchResults();
                const searchResultHighlights = createSearchResultHighlights(pageSearchResults, instance, selectedMarkStyle);
                instance.Core.annotationManager.addAnnotations(searchResultHighlights)
                instance.Core.documentViewer.clearSearchResults()
                instance.Core.documentViewer.trigger('processEnded', 'search')
            }, 10);
        }
    }, [searchStatus]);

    const deleteSearchResults = async (resultsToDelete: any[]) => {
        setSearchResults(prevState => prevState.filter((result) => !resultsToDelete.includes(result)));
        await instance.Core.annotationManager.deleteAnnotations(resultsToDelete)
    };

    const deleteAISearchResults = () => {
        deleteSearchResults(getAIGeneratedSearchResults())
    }

    const clearPatternsSearchResults = () => {
        deleteSearchResults(searchResults.filter((result) => !result.AIGenerated));
    }

    const getAIGeneratedSearchResults = () => {
        return searchResults.filter((result)=> result.AIGenerated)
    }

    const onCancelSearch = () => {
        setSearchTerms([]);
        //This doesn't just clear the search results, it also cancels the search.
        instance.Core.documentViewer.clearSearchResults();
        clearPatternsSearchResults()
        setIsProcessingRedactionResults(false);
        setSearchStatus(SearchStatus.SEARCH_DONE);
        cancellationTokenRef.current.cancelled = true;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const onAnnotationsChanged = (annotations: any[], action: string, { imported }: any) => {
            if (imported) {
                return;
            }
            if (annotations.find((annotation: any) => annotation.getCustomData('isSearchResult') === 'true')) {
                setSearchResults(loadSearchResultHighlights());
            }
        }

        const onAnnotationsLoaded = () => {
            setSearchResults(loadSearchResultHighlights());
        }

        if (instance) {
            instance.Core.documentViewer.addEventListener('annotationsLoaded', onAnnotationsLoaded)
            instance.Core.annotationManager.addEventListener('annotationChanged', onAnnotationsChanged);
            instance.Core.documentViewer.addEventListener('searchResultsCleared', handleClearTerms)
        }
        return () => {
            if (instance) {
                instance.Core.documentViewer.removeEventListener('annotationsLoaded', onAnnotationsLoaded)
                instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationsChanged);
                instance.Core.documentViewer.removeEventListener('searchResultsCleared', handleClearTerms)
            }
        }
    }, [instance]);

    const handleAdvanceOptionsClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNlpJson = (json: JSON) => {
        deleteAISearchResults()
        loadNLPRedactionSearch(instance.Core.documentViewer, json, instance.Core.Search.Mode, setSearchStatus, filterFunction.function);
    }

    const handleClearTerms = () => {
        setSearchTerms([]);
        instance.Core.documentViewer.trigger('processEnded', 'searchCleared')
    }

    return <div className={'panel'} style={{height: '1000px'}}>
        <RedactionSearchMultiSelect searchTerms={searchTerms} setSearchTerms={setSearchTerms}
                                    redactionSearchPatterns={props.redactionSearchPatterns} pageRange={pageRange}
                                    wholeWord={wholeWord}
                                    setSearchStatus={setSearchStatus}/>
        <button className='clear-button' onClick={handleClearTerms}>Clear all</button>
        <div className="search-options-container">
            <div>
            <div className="extra-options">
                <button className='Button' onClick={handleAdvanceOptionsClick}>Advanced Options
                    {Boolean(anchorEl) ? "  ↑" : "  ↓"}</button>
            </div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={'transform-menu'}
                    style={{ zIndex: 99999 }}
                >
                    <MenuItem onClick={() => {handleClose();showModal(ChangeMarginsModal, {})}}>Change Margins</MenuItem>
                    <MenuItem title={'Bulk search allows you to add a list of terms to be searched within the currently displayed document'}
                              onClick={() => {handleClose();showModal(BulkSearchTerms, {})}}>Bulk Search Terms</MenuItem>
                    <MenuItem onClick={() => {
                        handleClose(); showModal(UploadNlpResults, {
                            handleNlpJson: handleNlpJson
                        })
                    }}>Load AI Annotations</MenuItem>
                    <MenuItem onClick={() => {setWholeWord(prevState => !prevState)}}>
                        <input type={"checkbox"} readOnly={true}
                               checked={wholeWord} style={{marginRight: 10}}/> Whole Words</MenuItem>
                </Menu>
            </div>
            <div style={{marginLeft: "auto", marginBottom: "auto"}}>
                <PageRangeInput pageRangeChangeCallBack={(newPageRange) => setPageRange(newPageRange)}
                                clearPageRangeCallBack={pageRangeOnClear} placeholder={'Page Range e.g. 1-10'}/>
            </div>
        </div>

        <RedactionSearchResultsContainer
            redactionSearchResults={searchResults}
            deleteSelectedResults={deleteSearchResults}
            onCancelSearch={onCancelSearch}
            searchStatus={searchStatus}
            isProcessingRedactionResults={isProcessingRedactionResults}
            selectedTab={props.selectedTab}
        />
    </div>;
});

export function UploadNlpResults(props : { handleNlpJson: (json: JSON) => void, }) {
    const { hideModal } = useCustomModal();

    const handleSubmit = async (files: { file: any }[], allFiles: any[]) => {
      const filetext = await files[0].file.text();
      const json = JSON.parse(filetext);

      props.handleNlpJson(json);
      hideModal();
    };

    return (
        <div className="upload-file-modal-container">
            <div className="wizard-title">
                <StyledCloseIconUploader onClick={hideModal} />
                <span>{"Upload AI Annotations"}</span>
            </div>
            <Dropzone
                onSubmit={handleSubmit}
                maxFiles={1}
                accept={".json"}
                inputContent="Click here or drag and drop"
                submitButtonDisabled={files => files.length < 1}
                styles={{ dropzone: { minHeight: 450, minWidth: 400, padding:8 } }}
            />
        </div>)
}
