import { configureStore } from "@reduxjs/toolkit";
import projectTemplates from "./projectTemplates";
import projects from "./projects";
import userSettings from "./userSettings";
import progressLine from "./progress-line";
import snackbar from "./snackbar";
import taskLists from "./taskLists";
import users from "./users";
import projectFilters from "./project-filters";
import actionBar from "./action-bar";
import pageMargins from "./pagemargins"
import customFields from "./custom-fields";
import notifications from "./notifications"
import taskTimeTracking from './tasksTimeTracking'
import deletedTasks from "./deleted-tasks";
import accountList from "./account-list";
import patternSets from "./patternSets";
import files from "./file-management";
import assets from "./assets";
import categories from "./category";
import CI from './ci';
import justificationCategories from './justificationCategory';
import rejectionCategories from "./rejectionCategory";
import document from "./document";

const store = configureStore({
    reducer: {
        userSettings,
        projectTemplates,
        projects,
        progressLine,
        snackbar,
        taskLists,
        users,
        projectFilters,
        actionBar,
        customFields,
        notifications,
        taskTimeTracking,
        deletedTasks,
        accountList,
        patternSets,
        files,
        pageMargins,
        assets,
        categories,
        CI,
        justificationCategories,
        rejectionCategories,
        document,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
