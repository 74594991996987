import moment from "moment";

export const adminRoleBody = "An administrator has access to all the accounts and full control on the platform.<br />" +
    "The actions that an Admin has access to are displayed below.";

export const pmRoleBody = "A Project Manager has access to all the projects and tasks within that account.<br />" +
    "The actions that a Project Manager has access to are displayed below.";

export const fmRoleBody = "A File Manager have access to all the projects and tasks shared with them. They can also manage the files in the projects.<br />" +
    "The actions that a Redactor has access to are displayed below.";

export const userRoleBody = "Members have access to all the projects and tasks shared with them.<br />" +
    "The actions that a Member has access to are displayed below.";

export const redactionRoleBody = "Redaction Specialists have access to all the projects and tasks shared with them.<br />" +
    "The actions that a Redactor has access to are displayed below.";

export const anonymizeRoleBody = "Anonymization Specialists have access to all the projects and tasks shared with them.<br />" +
    "The actions that an anonymizer has access to are displayed below.";

export const viewRoleBody = "View only user have access to all the projects and tasks shared with them.<br />" +
    "The actions that a View only user has access to are displayed below.";

export const accountManagerBody = "A user with multi-account access can log in into multiple accounts using one email address and password.<br />" +
    "The permissions for the users with multi-account access are based on the roles that are assigned to them in those specific accounts.<br />" +
    "For more information regarding the account roles please go back to <b>Roles overview</b>.";

export const superAdminBody = "A super admin has access to all the accounts and full control on the platform.<br />" +
    "The actions that a Super Admin has access to are displayed below.";

export const adminRolePermissions = ["Add account", "Invite users", "Remove account", "Remove users", "Edit account",
    "Edit users", "Assign role", "Add project", "Add task", "Add list", "Create project template", "Share project",
    "Assign task", "Edit project", "Edit task", "Edit subtask", "Remove project", "Delete task/subtask",
    "Change project status", "Change task status", "View project", "View task", "View list", "Track time", "Edit time",
    "View project template", "Edit project template", "Delete project template", "Rename project template", "Commenting",
    "Docs access", "Run redaction process", "Run anonymization process", "View redacted documents", "Edit redacted documents"];

export const pmRolePermissions = ["Add project", "Add task", "Add list", "Create project template", "Share project",
    "Assign task", "Edit project", "Edit task", "Edit subtask", "Remove project", "Delete task/subtask",
    "Place project on hold", "Place task on hold", "View project", "View task", "View list", "Track time", "Edit time",
    "View project template", "Edit project template", "Delete project template", "Rename project template", "Commenting",
    "Docs access", "Run redaction process", "Run anonymization process", "View redacted documents", "Edit redacted documents"];

export const fmRolePermissions = ["Change task status", "View project", "View task", "View list",
    "Track time", "Edit time", "Commenting", "Docs access", "Run redaction process", "View redacted documents", "Edit redacted documents", "Manage Project Files"];

export const userRolePermissions = ["Change task status", "View project", "View task", "View list",
    "Track time", "Edit time", "Commenting"];

export const redactionRolePermissions = ["Change task status", "View project", "View task", "View list",
    "Track time", "Edit time", "Commenting", "Docs access", "Run redaction process", "View redacted documents", "Edit redacted documents"];

export const anonymizeRolePermissions = ["Change task status", "View project", "View task", "View list",
    "Track time", "Edit time", "Commenting", "Docs access", "Run anonymization process", "View redacted documents", "Edit redacted documents"];

export const viewRolePermissions = ["View project", "View task", "View list"];

export const superAdminPermissions = ["Add account", "Invite users", "Remove account", "Remove users", "Edit account", "Edit users",
    "Assign role", "Add project", "Add task", "Add list", "Create project template", "Share project", "Assign task", "Edit project", "Edit task",
    "Edit subtask", "Remove project", "Delete task/subtask", "Change project status", "Change task status", "View project", "View task", "View list",
    "Track time", "Edit time", "View project template", "Edit project template", "Delete project template", "Rename project template", "Commenting",
    "Docs access", "Run redaction process", "Run anonymization process", "View redacted documents", "Edit redacted documents"];

export const colorPalette = [
    { color: "#5C87FF" },
    { color: "#D9457B" },
    { color: "#477D4A" },
    { color: "#58606E" },
    { color: "#87295B" },
    { color: "#FF9A4D" },
    { color: "#2D5BA1" },
    { color: "#DEAC22" },
    { color: "#B16DBD" },
    { color: "#989693" },
    { color: "#59308C" },
    { color: "#3B79BF" },
    { color: "#E66E2E" },
    { color: "#5C6BC0" },
    { color: "#146163" },
    { color: "#A280FF" },
    { color: "#43A08E" },
    { color: "#D45959" },
]

export const blueIconColor = "#5dabd3"

export type StatusType = "NOT_STARTED" | "IN_PROGRESS" | "IN_REVIEW" | "COMPLETE" | "CANCELED" | "ON_HOLD"

export const statusList = {
    NOT_STARTED: {
        color: "#0277BD",
        leftColor: "#42A5F5",
        name: "NOT STARTED"
    },
    IN_PROGRESS: {
        color: "#BF360C",
        leftColor: "#EF5350",
        name: "IN PROGRESS"
    },
    IN_REVIEW: {
        color: "#7E57C2",
        leftColor: "#9575CD",
        name: "IN REVIEW"
    },
    ON_HOLD: {
        color: "#8D6E63",
        leftColor: "#A1887F",
        name: "ON HOLD"
    },
    CANCELED: {
        color: "#58595B",
        leftColor: "#757575",
        name: "CANCELED"
    },
    COMPLETE: {
        color: "#557B2A",
        leftColor: "#78AD3B",
        name: "COMPLETE"
    }
}

export const redactionTypeMap = {
    REGION: 'region',
    TEXT: 'text',
    BULKSEARCH: 'bulkSearch',
    FULL_PAGE: 'fullPage',
    FULL_VIDEO_FRAME: 'fullVideoFrame',
    AUDIO_REDACTION: 'audioRedaction',
    FULL_VIDEO_FRAME_AND_AUDIO: 'fullVideoFrameAndAudio',
    CREDIT_CARD: 'creditCard',
    PHONE: 'phone',
    IMAGE: 'image',
    EMAIL: 'email',
};

export const defaultRedactionTypes = {
    [redactionTypeMap['REGION']]: {
        icon: 'icon-tool-redaction-area',
        label: 'redactionPanel.redactionItem.regionRedaction',
    },
    [redactionTypeMap['FULL_PAGE']]: {
        icon: 'icon-header-page-manipulation-page-transition-page-by-page-line',
        label: 'redactionPanel.redactionItem.fullPageRedaction',
    },
    [redactionTypeMap['FULL_VIDEO_FRAME']]: {
        icon: 'ic-full-frame-video-redact',
        label: 'redactionPanel.redactionItem.fullVideoFrameRedaction',
    },
    [redactionTypeMap['AUDIO_REDACTION']]: {
        icon: 'ic-audio-redact',
        label: 'redactionPanel.redactionItem.audioRedaction',
    },
    [redactionTypeMap['FULL_VIDEO_FRAME_AND_AUDIO']]: {
        icon: 'ic-full-frame-video-and-audio-redact',
        label: 'redactionPanel.redactionItem.fullVideoFrameAndAudioRedaction',
    },
    [redactionTypeMap['TEXT']]: {
        icon: 'icon-form-field-text',
    },
    [redactionTypeMap['IMAGE']]: {
        icon: 'redact-icons-image',
        label: 'redactionPanel.redactionItem.image',
    }
};

export const mapAnnotationToRedactionType = (annotation: any) => {
    const isTextRedaction = annotation.IsText;
    if (isTextRedaction) {
        return redactionTypeMap['TEXT'];
    }

    const { type = 'region' } = annotation;
    //All of our custom categories like 'age', 'date', go here and are not valid options. So in that case return TEXT.
    const values = Object.values(redactionTypeMap);
    return values.includes(type) ? type : redactionTypeMap['TEXT']
};

export const categoricalTransformations = {
    "names": [
        "(Name)"
    ],
    "names_invsponsor": [
        "(Name)"
    ],
    "author_name_references": [
        "(Name)"
    ],
    "date": [
        "(Date)"
    ],
    "time": [
        "(Time)"
    ],
    "site_id": [
        "(ID)",
        "(Site ID)"
    ],
    "inv_id": [
        "(ID)",
        "(Inv ID)"
    ],
    "country": [
        "(Country)",
        "(Ctry)",
        "(Co)"
    ],
    "participant_id": [
        "(Participant ID)",
        "(PID)",
        "(ID)"
    ],
    "other_participant_id": [
        "(ID)",
        "(Rec No.)"
    ],
    "randomization_code": [
        "(Randomization ID)",
        "(ID)",
        "(Rand ID)"
    ],
    "treatment_id": [
        "(Treatment ID)",
        "(ID)",
        "(Trt ID)",
        "(Tx ID)"
    ],
    "nonmem_id": [
        "(ID)",
        "(Nonmem ID)",
        "(NM ID)"
    ],
    "case_id": [
        "(ID)",
        "(Case ID)"
    ],
    "donor_number": [
        "(ID)",
        "(Donor ID)"
    ],
    "age": [
        "(Age)",
        "(A)"
    ],
    "age_range": [
        "(Age Range)"
    ],
    "gender": [
        "(Sex)",
        "(S)"
    ],
    "ethnicity": [
        "(Ethnicity)",
        "(E)"
    ],
    "race": [
        "(Race)",
        "(R)"
    ],
    "height": [
        "(Height)",
        "(H)"
    ],
    "weight": [
        "(Weight)",
        "(W)"
    ],
    "bmi": [
        "(BMI)",
        "(B)"
    ],
    "bsa": [
        "(BSA)"
    ],
    "hipcir": [
        "(HIPCIR)"
    ],
    "wstcir": [
        "(WSTCIR)"
    ],
    "dateofbirth": [
        "(Date)",
        "(Year)"
    ],
    "dateofdeath": [
        "(Date)"
    ],
    "date_inc": [
        "(Date)"
    ],
    "relativeday": [
        "(Date)"
    ],
    "mhterms": [
        "(Med. History)",
        "(MH)"
    ],
    "cmterms_geog": [
        "(Con. Med)",
        "(CM)"
    ],
    "cmterms_mh_generic": [
        "(Con. Med)",
        "(CM)"
    ],
    "cmterms_mh_brand": [
        "(Con. Med)",
        "(CM)"
    ],
    "cmterms_ae_generic": [
        "(Con. Med)",
        "(CM)"
    ],
    "cmterms_ae_brand": [
        "(Con. Med)",
        "(CM)"
    ],
    "cmterms_genderspecific": [
        "(Con. Med)",
        "(CM)"
    ]
}

export const markStyles = [
    {
        name: 'CTR', id: 1, props: {
            Font: 'serif',
            FontSize: '9pt',
            normal: {
                OverlayText: 'PPD',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 114, "G": 203, "B": 235, "A": 1 },
                FillColor: { "R": 114, "G": 203, "B": 235, "A": 1 }
            },
            cci: {
                OverlayText: 'CCI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cbi: {
                OverlayText: 'CCI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            }
        }
    },
    {
        name: 'EMA-Policy-0070', id: 2, props: {
            Font: 'serif',
            FontSize: '9pt',
            normal: {
                OverlayText: 'PPD',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 114, "G": 203, "B": 235, "A": 1 },
                FillColor: { "R": 114, "G": 203, "B": 235, "A": 1 }
            },
            cci: {
                OverlayText: 'CCI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cbi: {
                OverlayText: 'CCI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            }
        }
    },
    {
        name: 'HC-PRCI', id: 3, props: {
            Font: 'serif',
            FontSize: '9pt',
            normal: {
                OverlayText: 'PI',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 114, "G": 203, "B": 235, "A": 1 },
                FillColor: { "R": 114, "G": 203, "B": 235, "A": 1 }
            },
            cci: {
                OverlayText: 'CBI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cbi: {
                OverlayText: 'CBI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            }
        }
    },
    {
        name: 'HC-PRCI-White-Overlay', id: 4, props: {
            Font: 'serif',
            FontSize: '9pt',
            normal: {
                OverlayText: 'PI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 255, "B": 255, "A": 1 },
                StrokeColor: { "R": 114, "G": 203, "B": 235, "A": 1 },
                FillColor: { "R": 114, "G": 203, "B": 235, "A": 1 }
            },
            cci: {
                OverlayText: 'CBI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cbi: {
                OverlayText: 'CBI',
                TextAlign: 'left',
                TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            }
        }
    },
    {
        name: 'Plain Black', id: 5, props: {
            Font: 'serif',
            FontSize: '9pt',
            normal: {
                OverlayText: '',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cci: {
                OverlayText: '',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cbi: {
                OverlayText: '',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            }

        }
    },
    {
        name: 'Custom', id: 6, props: {
            Font: 'serif',
            FontSize: '9pt',
            normal: {
                OverlayText: '',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cci: {
                OverlayText: '',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            },
            cbi: {
                OverlayText: '',
                TextAlign: 'left',
                TextColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
                FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
            }

        }
    },
]

export const fullPageRedactionStyle = {
    Font: 'serif',
    FontSize: '128pt',
    TextAlign: 'center',
    Opacity: 1,
    OverlayText: 'Out Of Scope',
    StrokeThickness: 1.5,
    TextColor: { "R": 255, "G": 0, "B": 0, "A": 1 },
    StrokeColor: { "R": 0, "G": 0, "B": 0, "A": 1 },
    FillColor: { "R": 0, "G": 0, "B": 0, "A": 1 }
}

export type DueDateType = "OVERDUE" | "TODAY" | "CURRENT_WEEK" | "NEXT_WEEK" | "NO_DUE_DATE"

export const compareDueDateRanges = (dueDate1: string | null | undefined, dueDate2: string | null | undefined) => {
    let areDueDatesInSameCondition = false;
    let flag = false;
    Object.keys(dueDateList).map(dd => {
        areDueDatesInSameCondition = (dueDateList[dd as DueDateType].condition(dueDate1) === true) &&
            (dueDateList[dd as DueDateType].condition(dueDate2) === true);
        if (areDueDatesInSameCondition) {
            flag = true;
        }
    });
    return flag;
}

export const dueDateList = {
    OVERDUE: {
        name: "Overdue",
        color: "#BF360C",
        condition: (date: string | undefined | null) => {
            if (date === undefined || date === null) {
                return false;
            }
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return new Date(date) < today;
        }
    },
    TODAY: {
        name: "Today",
        color: "#0277BD",
        condition: (date: string | undefined | null) => date !== undefined && date !== null &&
            new Date(date).toDateString() === new Date().toDateString()
    },
    CURRENT_WEEK: {
        name: "Current week",
        color: "#42A5F5",
        condition: (date: string | undefined | null) => {
            if (date === undefined || date === null) {
                return false;
            }
            const lastDay = moment().startOf("isoWeek").add(7, "days");
            return moment(date) > moment() && moment(date) < lastDay;
        }
    },
    NEXT_WEEK: {
        name: "Next week",
        color: "#42A5F5",
        condition: (date: string | undefined | null) => {
            if (date === undefined || date === null) {
                return false;
            }
            const firstDay = moment().startOf("isoWeek").add(7, "days");
            const lastDay = moment().startOf("isoWeek").add(14, "days");
            return moment(date).valueOf() >= firstDay.valueOf() && moment(date).valueOf() < lastDay.valueOf()
        }
    },
    NO_DUE_DATE: {
        name: "No due date",
        color: "#58595B",
        condition: (date: string | undefined | null) => date === undefined || date === null
    }
}

export const ANNOTATION_ID_COLUMN = "Annotation ID";
export const FILE_NAME_COLUMN = "File Name";
export const MATCH_CATEGORY_COLUMN = "Match Category";
export const OCCURRENCE_COLUMN = "Occurrence";
export const ORDERING_ID_COLUMN = "Ordering ID";
export const ORIGINAL_TEXT_COLUMN = "Original";
export const PAGE_NUMBER_COLUMN = "Page Number";
export const PATIENT_ID_COLUMN = "Patient ID";
export const REPLACEMENT_TEXT_COLUMN = "Replacement";
export const HIGHLIGHT_TEXT = "Highlight Text";
export const COMMENTS_COLUMN = "Comment";
export const STANDARDIZED_DATE_COLUMN = "Standardized Date";
export const DATE_FORMAT = "Date Format";
export const START_DATE_COLUMN = "Start Date";
export const STUDY_ID_COLUMN = "Study ID";
export const RANDOM_DATE_OFFSET_COLUMN = "Random Date Offset";
export const STATUS_COLUMN = "Status";
export const CREATION_DATE_COLUMN = "Creation Date";
export const USERNAME = "Username";
export const APPLIED_RULES = "Applied Rule(s)";
export const REPLACEMENT_METHOD = "Replacement Method";
export const ANNOTATION_TYPE = "Annotation Type";
export const ANNOTATION_LOCATION_COLUMN = "Location";
export const QUADS = "Rectangle-Coordinates";
export const REPLACEMENT_FONT = "Replacement Font";
export const REPLACEMENT_FONT_SIZE = "Replacement Font Size";

export const MATCH_CATEGORY_SEPARATOR = ",";

export const PATIENT_ID_CATEGORY = "patient_id";
export const PARTICIPANT_ID_CATEGORY = "participant_id";
export const STUDY_ID_CATEGORY = "study_id";
export const DATE_CATEGORY = "date";
export const START_INDEX = "Start Index";
export const END_INDEX = "End Index";
export const REPLACED_START_INDEX = "Replacement Text Start Index";
export const REPLACED_END_INDEX = "Replacement Text End Index";

//for CIOMS
export const CLEANED_TEXT_COLUMN = "Cleaned Text";

export const MAX_PAGE_RANGE_DISTANCE=2
export const PREFERRED_DATE_FORMAT = "MONTH/DAY/YEAR"

export const CATEGORY_SPLITTER = '--';

export const DESCRIPTION_ROW:Map<string, string> =
    new Map([
        [FILE_NAME_COLUMN, "Name of the file"],
        [ANNOTATION_TYPE, "Annotation Type of the item in this row"],
        [ORIGINAL_TEXT_COLUMN, "Text from the original document"],
        [REPLACEMENT_TEXT_COLUMN, "Replacement text here"],
        [COMMENTS_COLUMN, "Annotation comment"],
        [PAGE_NUMBER_COLUMN, "The page number"],
        [OCCURRENCE_COLUMN, "The nth occurrence of the original text on the page"],
        [ANNOTATION_ID_COLUMN, "A unique ID used by the library"],
        [ORDERING_ID_COLUMN, "ID representing the order in which annotation occurred in pdf (sorted top-to-bottom left-to-right), meant to be used for getting original ordering."],
        [MATCH_CATEGORY_COLUMN, "The category of regex used to match the original text"],
        [PATIENT_ID_COLUMN, "The associated patient ID"],
        [START_DATE_COLUMN, "The associated start date"],
        [STANDARDIZED_DATE_COLUMN, "The standardized start date"],
        [STUDY_ID_COLUMN, "The associated study ID"],
        [STATUS_COLUMN, "Status of the Annotation: Accepted, Rejected, or None"],
        [CREATION_DATE_COLUMN, "The timestamp when this annotation was created"],
        [USERNAME, "The name of the user who created this annotation"],
        [APPLIED_RULES, "The names of any rules that changed the replacement text"],
        [REPLACEMENT_METHOD, "The method used to transform the original text to the replacement text on the page"],
        [QUADS, "Coordinate locations of rectangles on page"]
    ]);

export const INACTIVE_TIME_LIMIT_IN_PLATFORM = 900000; //15 minutes in milliseconds
export const INACTIVE_TIME_LIMIT_OUTSIDE_PLATFORM = 120000; //2 minutes
export const TIME_TO_SHOW_INACTIVE_TIMER = 600000; //10 minutes

export const CIM_ROLES = {CIM_APPROVER:"CIM Approver",CIM_REVIEWER:"CIM Reviewer",CIM_MANAGER:"Manager" };
export type CIMRoleKey ="CIM_APPROVER" | "CIM_REVIEWER" | "CIM_MANAGER";