import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthService } from '../../../../../contexts/auth-context';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hook';
import { Asset } from '../../../../../models/Asset';
import { CI } from '../../../../../models/CI';
import { ciWorkFlowStatus, CIWorkFlowStatusType } from '../../../../../models/ciWorkFlowStatus';
import { loadAssets } from '../../../../../redux/assets';
import { loadCIs, loadFilteredCIs, setCILoading } from '../../../../../redux/ci';
import { hideProgressLine, showProgressLine } from '../../../../../redux/progress-line';
import { getApiAssets } from '../../../../../services/assets';
import { getApiCIs } from '../../../../../services/ci';
import { fetchUsers } from '../../../../../services/init';
import CIStatusChip from '../../component/ciStatusChip';
import { CategoryList } from '../categoryItem';
import ListSkeleton from './listSkeleton';
import ViewCIDetailDrawer from './viewCIDetailDrawer';

export const ViewCIList = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const auth = useAuthService();
    const ciIdFromQuery = searchParams.get("ciId");

    const ciObj = useAppSelector((state) => state.CI);
    const ci = ciObj.CI;
    const ciFiltered = ciObj.filteredCI;
    const ciLoading = ciObj.loading;
    const assetsObj = useAppSelector((state) => state.assets);
    const assets = assetsObj.assets;
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<keyof typeof ciWorkFlowStatus | null>(null);
    const [selectedCIId, setSelectedCIId] = useState<number | null>(ciIdFromQuery ? Number(ciIdFromQuery) : null);

    const roles = auth?.loginInfo?.tenant?.user?.roles;

    const loadAssetsData = useCallback(async () => {
        const assetsList = await getApiAssets();
        dispatch(loadAssets(assetsList));
    }, [dispatch]);

    useEffect(() => {
        loadAssetsData();
    }, [loadAssetsData]);

    useEffect(() => {
        const controller = new AbortController();
        fetchUsers(controller.signal, dispatch);
        return () => controller.abort();
    }, []);

    const loadCIData = useCallback(async () => {
        dispatch(showProgressLine());
        dispatch(setCILoading(true))
        try {
            const allCIs = await getApiCIs();
            dispatch(loadCIs(allCIs));
            dispatch(hideProgressLine());
        } catch (err) {
            dispatch(hideProgressLine());
        } finally {
            dispatch(setCILoading(false))

        }
    }, [dispatch]);

    useEffect(() => {
        loadCIData();
    }, [dispatch, loadCIData]);

    useEffect(() => {
        let filteredCatList = ci;
        if (selectedAsset) {
            filteredCatList = ci.filter((item) => item.assetId === selectedAsset.id);
        }
        if (selectedStatus) {
            filteredCatList = filteredCatList.filter(item => item.status === selectedStatus)
        }

        dispatch(loadFilteredCIs(filteredCatList));
    }, [ci, selectedAsset, selectedStatus]);

    const handleAssetChange = (event: any, newValue: Asset | null) => {
        if (newValue) {
            setSelectedAsset(newValue);
        } else {
            setSelectedAsset(null);
        }
    };

    const handleStatusChange = (event: any, newValue: keyof typeof ciWorkFlowStatus | null) => {
        setSelectedStatus(newValue);
    };

    const filteredStatuses = Object.keys(ciWorkFlowStatus)
        .map(Number)
        .filter((key: CIWorkFlowStatusType) => {
            const status = ciWorkFlowStatus[key];
            return roles?.some(role => status.roles?.includes(role));
        });


    return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "16px",
                    backgroundColor: "#fff",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            CI List ({ciFiltered.length || 0})
                        </Typography>
                        <Box style={{ display: "flex", gap: 16 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                                {!selectedAsset ? <Autocomplete
                                    size="small"
                                    value={selectedAsset}
                                    onChange={handleAssetChange}
                                    options={assets}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Asset"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>{option.name}</li>
                                    )}
                                    sx={{
                                        width: '250px',
                                        '& .MuiAutocomplete-inputRoot': {
                                            padding: '8px 12px',
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                /> : (
                                    <Chip
                                        label={selectedAsset.name}
                                        color="primary"
                                        onDelete={() => setSelectedAsset(null)}
                                        sx={{
                                            borderRadius: '16px',
                                            fontWeight: 'bold',
                                            minHeight: '36px',
                                            height: '36px',
                                        }}
                                    />
                                )}
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                                {!selectedStatus ? (
                                    <Autocomplete
                                        size="small"
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        options={filteredStatuses}
                                        getOptionLabel={(status) => ciWorkFlowStatus[status].label}
                                        sx={{ width: 250 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Status"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        renderOption={(props, status) => (
                                            <li {...props}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <CIStatusChip status={status} />
                                                </Box>
                                            </li>
                                        )}
                                    />
                                ) : (<CIStatusChip onDelete={() => setSelectedStatus(null)} status={selectedStatus} />

                                )}
                            </Box>
                        </Box>
                    </Box>
                    {ciLoading ? <ListSkeleton /> : <CategoryList items={ciFiltered} onItemClick={(clickedCI: CI) => setSelectedCIId(clickedCI.id)} />}
                </Box>
                {selectedCIId && <ViewCIDetailDrawer onRefresh={loadCIData} onClose={() => { setSelectedCIId(null); }} open ciId={selectedCIId} />}
            </Box>
        </Box>
    );
};
