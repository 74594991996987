import EditIcon from '@mui/icons-material/Edit';
import { MenuItem } from '@mui/material';
import React from 'react';
import { useCimUserRoles } from '../../../../../hooks/useCimUserRoles';
import { CI } from '../../../../../models/CI';
import useEditCI from '../../hook/useEditCI';


type EditMenuItemProps = {
    handleMenuClose: () => void;
    ciData: CI;
    onSuccess: () => void;
    loading: boolean;
};

export const EditMenuItem: React.FC<EditMenuItemProps> = ({
    handleMenuClose,
    ciData,
    onSuccess,
    loading
}) => {

    const { isManager } = useCimUserRoles();

    const { showEditCIModal } = useEditCI();

    const onEdit = () => {
        showEditCIModal({ onSuccess, ciData });
        handleMenuClose();
    }

    if (!isManager) {
        return null
    }
    
    return <MenuItem
        onClick={onEdit}
        disabled={loading}
        sx={{
            '&:hover': {
                backgroundColor: '#555',
                color: '#fff',
                transition: 'all 0.3s ease',
            },
        }}
    >
        <EditIcon sx={{ mr: 1, }} />
        Edit
    </MenuItem>
}