import { InfoTenantUser } from "./InfoTenantUser";

export const ciWorkFlowStatusObj = {
    DRAFT: 1,
    SUBMITTED_FOR_REVIEW: 2,
    REJECTED_FROM_REVIEW: 3,
    IN_APPROVAL: 4,
    REJECTED_FROM_APPROVAL: 5,
    APPROVED: 6,
    ON_HOLD: 7,
    PUBLIC: 8,
  } as const; 


export const ciWorkFlowStatus: Record<number, { label: string; color: string ,textColor:string,roles:string[] }> = {
    [ciWorkFlowStatusObj.DRAFT]: {
        label: "Draft",
        color: "#d8d8d8",
        textColor:"#000",
        roles:["CIM_MANAGER","SUPER","ADMIN"],
    },
    [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW]: {
        label: "Submitted for Review",
        color: "#ffc000f7",
        textColor:"#000",
        roles:["CIM_MANAGER","SUPER","ADMIN","CIM_REVIEWER","VIEW"],

    },
    [ciWorkFlowStatusObj.REJECTED_FROM_REVIEW]: {
        label: "Rejected from Review",
        color: "#ff0000f0",
        textColor:"#fff",
        roles:["CIM_MANAGER","SUPER","ADMIN","CIM_REVIEWER","VIEW"],


    },
    [ciWorkFlowStatusObj.IN_APPROVAL]: {
        label: "In Approval",
        color: "#92d050e8",
        textColor:"#fff",
        roles:["CIM_MANAGER","SUPER","ADMIN","CIM_REVIEWER","CIM_APPROVER","VIEW"],


    },
    [ciWorkFlowStatusObj.APPROVED]: {
        label: "Approved",
        color: "#00b050e8",
        textColor:"#fff",
        roles:["CIM_MANAGER","SUPER","ADMIN","CIM_APPROVER","VIEW"],


    },
    [ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL]: {
        label: "Rejected from Approval",
        color: "#ff0000f0",
        textColor:"#fff",
        roles:["CIM_MANAGER","SUPER","ADMIN","CIM_APPROVER","VIEW"],

    },
    [ciWorkFlowStatusObj.ON_HOLD]: {
        label: "On Hold",
        color: "#ed7d31",
        textColor:"#fff",
        roles:["CIM_MANAGER","SUPER","ADMIN","VIEW"],


    },
    [ciWorkFlowStatusObj.PUBLIC]: {
        label: "Public",
        color: "#a0e6ff",
        textColor:"#fff",
        roles:["CIM_MANAGER","SUPER","ADMIN","VIEW"],

    },
  };
  

export type CIWorkFlowStatusType = keyof typeof ciWorkFlowStatus;


export const ciWorkFlowMessage: Record<number, (actionUser: InfoTenantUser | undefined) => string> = {
    [ciWorkFlowStatusObj.IN_APPROVAL]: (actionUser) => `${actionUser?.name} has moved CI in Approval`,
    [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW]: (actionUser) => `${actionUser?.name} has submitted CI for Review`,
    [ciWorkFlowStatusObj.REJECTED_FROM_REVIEW]: (actionUser) => `${actionUser?.name} has rejected CI in Review`,
    [ciWorkFlowStatusObj.APPROVED]: (actionUser) => `${actionUser?.name} has approved the CI`,
    [ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL]: (actionUser) => `${actionUser?.name} has rejected CI in Approval`,
    [ciWorkFlowStatusObj.ON_HOLD]: (actionUser) => `${actionUser?.name} has holded the CI`,

};