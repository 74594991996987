import { Asset } from "../models/Asset";
import { AssignUsersToAsset } from "../models/AssignUsersToAsset";
import { BaseAsset } from "../models/BaseAsset";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";

export function getApiAssets(signal?: AbortSignal): Promise<Asset[]> {
  const url = new URL('/api/assets', baseUrl);
  return fetchAndParse<Asset[]>(url.toString(), { method: 'GET', headers, signal, });
}
export function getApiAssetsById(id: number, signal?: AbortSignal): Promise<Asset> {
  const url = new URL(`/api/assets/${id}`, baseUrl);
  return fetchAndParse<Asset>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiAssetsById(id: number, signal?: AbortSignal): Promise<void> {
  const url = new URL(`/api/assets/${id}`, baseUrl);
  return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiAssetsCreate(body?: BaseAsset, signal?: AbortSignal): Promise<Asset> {
  const url = new URL(`/api/assets/create`, baseUrl);
  return fetchAndParse<Asset>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiAssetsAssignUsers(body?: AssignUsersToAsset, signal?: AbortSignal): Promise<void> {
  const url = new URL(`/api/assets/assign-users`, baseUrl);
  return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiUpdateAsset(id: number, body: BaseAsset, signal?: AbortSignal) {
  const url = new URL(`/api/assets/${id}`, baseUrl)
  return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
