import { AssignApproversToCI } from "../models/AssignApproversToCI";
import { AssignAssetsToCI } from "../models/AssignAssetsToCI";
import { AssignCategoriesToCI } from "../models/AssignCategoriesToCI";
import { AssignReviewersToCI } from "../models/AssignReviewersToCI";
import { BaseApproveCI } from "../models/BaseApproveCI";
import { BaseCI } from "../models/BaseCI";
import { BaseReviewCI } from "../models/BaseReviewCI";
import { CI } from "../models/CI";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";

export function getApiCIs(signal?: AbortSignal): Promise<CI[]> {
    const url = new URL('/api/ci', baseUrl);
    return fetchAndParse<CI[]>(url.toString(), { method: 'GET', headers, signal });
}
export function getApiCIById(id: number, signal?: AbortSignal): Promise<CI> {
    const url = new URL(`/api/ci/${id}`, baseUrl);
    return fetchAndParse<CI>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiCIById(id: number, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ci/${id}`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiCICreate(body?: BaseCI, signal?: AbortSignal): Promise<CI> {
    const url = new URL(`/api/ci/create`, baseUrl);
    return fetchAndParse<CI>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiCIAssignAssets(body?: AssignAssetsToCI, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ci/assign-assets`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiCIAssignCategories(body?: AssignCategoriesToCI, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ci/assign-categories`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiCIAssignApprovers(body?: AssignApproversToCI, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ci/assign-approvers`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function postApiCIAssignReviewers(body?: AssignReviewersToCI, signal?: AbortSignal): Promise<void> {
    const url = new URL(`/api/ci/assign-reviewers`, baseUrl);
    return fetchAndParse<void>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiUpdateCI(id: number, body: BaseCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci/${id}`, baseUrl)
    const ciWithAllData = { ...body }
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(ciWithAllData), headers, signal });
}

export function putApiApproveCI(id: number, body: BaseApproveCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci/approve/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}

export function putApiReviewCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci/review/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
