import { Box, Typography } from "@mui/material";
import React from "react";
import { ciWorkFlowStatus, ciWorkFlowStatusObj } from "../../../../models/ciWorkFlowStatus";

type WorkFlowLabelProps = {
  currentStatus: number;
  pathStyle?: any;
  wrapperStyle?: any;
  labelStyle?: any;
};


export const WorkFlowLabel: React.FC<WorkFlowLabelProps> = ({
  currentStatus,
  pathStyle = {},
  wrapperStyle = {},
  labelStyle = {},
}) => {
  let stopRendering = false;
  const getVisibleStatuses = (currentStatus: number) => {
    if (currentStatus === ciWorkFlowStatusObj.DRAFT) {
      return [ciWorkFlowStatusObj.DRAFT, ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, ciWorkFlowStatusObj.IN_APPROVAL, ciWorkFlowStatusObj.APPROVED];
    }
    if (currentStatus === ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW) {
      return [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, ciWorkFlowStatusObj.IN_APPROVAL, ciWorkFlowStatusObj.APPROVED];
    }
    if (currentStatus === ciWorkFlowStatusObj.REJECTED_FROM_REVIEW) {
      return [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, ciWorkFlowStatusObj.REJECTED_FROM_REVIEW];
    }
    if (currentStatus === ciWorkFlowStatusObj.IN_APPROVAL) {
      return [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, ciWorkFlowStatusObj.IN_APPROVAL, ciWorkFlowStatusObj.APPROVED];
    }
    if (currentStatus === ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL) {
      return [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, ciWorkFlowStatusObj.IN_APPROVAL, ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL];
    }
    if (currentStatus === ciWorkFlowStatusObj.APPROVED) {
      return [ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW, ciWorkFlowStatusObj.IN_APPROVAL, ciWorkFlowStatusObj.APPROVED];
    }
    if (currentStatus === ciWorkFlowStatusObj.ON_HOLD) {
      return [ciWorkFlowStatusObj.ON_HOLD];
    }
    return Object.keys(ciWorkFlowStatus).map(Number);
  };

  const visibleStatuses = getVisibleStatuses(currentStatus);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
      style={{ ...wrapperStyle }}
    >
      {Object.entries(ciWorkFlowStatus).map(([key, status]) => {
        const stepNumber = parseInt(key);

        if (stopRendering) {
          return null;
        }

        const isVisible = visibleStatuses.includes(stepNumber);

        const isCompleted = stepNumber <= currentStatus;

        if (!isVisible) {
          return null
        }

        return (
          <Box
            key={key}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "82px",
              position: "relative",
              ...pathStyle,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="29.4863 14.1975 81.2913 32.5039"
              width="82px"
              height="33px"
            >
              <clipPath id="p.0">
                <path
                  d="m0 0l720.0 0l0 540.0l-720.0 0l0 -540.0z"
                  clipRule="nonzero"
                />
              </clipPath>
              <g
                clipPath="url(#p.0)"
                transform="matrix(1, 0, 0, 1, -70.58091735839845, -110.91285705566406)"
              >
                <path
                  fill={isCompleted ? status.color : "#f0f0f0"}
                  d="m100.06719 125.110344l65.039375 0l16.251968 16.25196l-16.251968 16.251968l-65.039375 0l16.251968 -16.251968z"
                  fillRule="evenodd"
                />
              </g>
            </svg>
            <Typography
              variant="body2"
              sx={{
                fontWeight: isCompleted && isVisible ? 600 : 400,
                color: isCompleted && isVisible ? status.textColor : "#757575",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                lineHeight: "1.03",
                fontSize: "8px",
              }}
              style={{ ...labelStyle }}
            >
              {status.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
