import { ArrowBack } from '@mui/icons-material';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from '../../../../contexts/auth-context';
import { ViewCIList } from './viewCI/viewCIList';
import { CIMRoleKey } from '../../../../constants';

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ maxHeight: 'calc(100vh - 260px)', overflowY: 'auto' }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const CIManagement = () => {
  const nav = useNavigate();
  const auth = useAuthService();

  const [tabValue, setTabValue] = useState(0);

  const roles = auth?.loginInfo?.tenant?.user?.roles;

  const tabs = [
    {
      label: 'Manage CI',
      component: <ViewCIList />,
      roles: ["CIM_MANAGER", "SUPER", "ADMIN"] as CIMRoleKey[],
    },
    {
      label: 'Approve CI',
      component: <ViewCIList />,
      roles: ["CIM_APPROVER"] as CIMRoleKey[],
    },
    {
      label: 'Review CI',
      component: <ViewCIList />,
      roles: ["CIM_REVIEWER"] as CIMRoleKey[],
    },
    {
      label: 'View CI',
      component: <ViewCIList />,
      roles: ["VIEW"] as ("VIEW" | CIMRoleKey)[],
    },
  ];
  const filteredTabs = tabs.filter((tab) => tab.roles?.some(role => roles?.includes(role)));
  const accessibleTabs = filteredTabs.length > 0 ? filteredTabs : [];
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          zIndex: 1000,
          position: 'sticky',
          top: 0,
          paddingBottom: '0px',
        }}

      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ArrowBack
            className="arrow-back"
            onClick={() => nav(-1)}
            style={{ cursor: 'pointer' }}
          />
          <Typography variant="h6" sx={{ fontSize: '32px' }}>
            {'CI Work Flow'}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="CI Management Tabs"
            sx={{
              backgroundColor: '#fff',
              padding: '12px',
              borderBottom: '1px solid #ddd',
              zIndex: 1000,
              position: 'sticky',
              top: 56,
              paddingBottom: '0px',
            }}
          >
            {accessibleTabs.map((tab, index) => (
              <Tab key={index} label={tab.label} sx={{ fontSize: '16px', fontWeight: 600 }} />
            ))}
          </Tabs>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          {accessibleTabs.map((tab, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              {tab.component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
