import { BaseCategory } from "../models/BaseCategory";
import { Category } from "../models/Category";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";

export function getApiCategories(signal?: AbortSignal): Promise<Category[]> {
  const url = new URL('/api/category', baseUrl);
  return fetchAndParse<Category[]>(url.toString(), { method: 'GET', headers, signal, });
}
export function getApiCategoryById(id: number, signal?: AbortSignal): Promise<Category> {
  const url = new URL(`/api/category/${id}`, baseUrl);
  return fetchAndParse<Category>(url.toString(), { method: "GET", headers, signal });
}
export function deleteApiCategoryById(id: number, signal?: AbortSignal): Promise<void> {
  const url = new URL(`/api/category/${id}`, baseUrl);
  return fetchAndParse<void>(url.toString(), { method: "DELETE", headers, signal });
}
export function postApiCategoryCreate(body?: BaseCategory, signal?: AbortSignal): Promise<Category> {
  const url = new URL(`/api/category/create`, baseUrl);
  return fetchAndParse<Category>(url.toString(), { method: "POST", body: JSON.stringify(body), headers, signal });
}
export function putApiUpdateCategory(id: number, body: Category, signal?: AbortSignal) {
  const url = new URL(`/api/category/${id}`, baseUrl)
  return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
