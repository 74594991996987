import { Autocomplete, Box, Button, FormControl, TextField } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UsersSelect } from "../../../../components/users-select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hook";
import { Asset } from "../../../../models/Asset";
import { BaseCI } from "../../../../models/BaseCI";
import { Category } from "../../../../models/Category";
import { InfoTenantUser } from "../../../../models/InfoTenantUser";
import { JustificationCategory } from "../../../../models/JustificationCategory";
import { loadAssets } from "../../../../redux/assets";
import { loadCategories } from "../../../../redux/category";
import { loadJustificatiobCategories } from "../../../../redux/justificationCategory";
import { showSnackbar } from "../../../../redux/snackbar";
import { usersCIMSelector } from "../../../../redux/users";
import { getApiAssets } from "../../../../services/assets";
import { getApiCategories } from "../../../../services/category";
import { postApiCICreate, putApiUpdateCI } from "../../../../services/ci";
import { getApiJustificationCategories } from "../../../../services/justificationCategory";
import { getUpdatedFieldsValues } from "../../../../tools/react-from";


interface CategoryFormData {
  assetId: Asset;
  category: Category;
  knownCI: string;
  ciEffectiveDate: string;
  publicDate: string;
  justificationCategoryId: JustificationCategory;
  justificationText?: string;
  approvers: number[];
  reviewers: number[];

}


const mockEditData = (data: CategoryFormData): BaseCI => {
  return {
    assetId: data.assetId.id,
    categories: [data.category.id],
    justificationText: data?.justificationText,
    justificationCategoryId: data?.justificationCategoryId?.id,
    knownCI: data.knownCI,
    ciEffectiveDate: moment(data.ciEffectiveDate).toISOString(),
    publicDate: data.publicDate ? moment(data.publicDate).toISOString() : undefined,
    approvers: data.approvers,
    reviewers: data.reviewers,
  };
};

const AddCIForm: React.FC = (props: any) => {
  const { hideModal, onRefersh, defaultData } = props;
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CategoryFormData>();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const approvers = useAppSelector((state) => usersCIMSelector(state, "CIM_APPROVER"))
  const reviewers = useAppSelector((state) => usersCIMSelector(state, "CIM_REVIEWER"))
  const assetsObj = useAppSelector((state) => state.assets);
  const assets = assetsObj.assets;
  const categoriesObj = useAppSelector((state) => state.categories);
  const categories = categoriesObj.categories;
  const justificationCategoriesObj = useAppSelector((state) => state.justificationCategories);
  const justificationcategories = justificationCategoriesObj.justificationCategories;

  const loadAssetsData = useCallback(async () => {
    const assetsList = await getApiAssets();
    dispatch(loadAssets(assetsList));
  }, [dispatch]);

  useEffect(() => {
    loadAssetsData();
  }, [loadAssetsData])

  const loadCategoryData = useCallback(async () => {
    const categoriesList = await getApiCategories();
    dispatch(loadCategories(categoriesList));
  }, [dispatch]);

  useEffect(() => {
    loadCategoryData();
  }, [loadCategoryData])


  const loadJustificationCategoryData = useCallback(async () => {
    const justificationCategoriesList = await getApiJustificationCategories();
    dispatch(loadJustificatiobCategories(justificationCategoriesList));
  }, [dispatch]);

  useEffect(() => {
    loadJustificationCategoryData();
  }, [loadJustificationCategoryData])

  useEffect(() => {
    if (defaultData) {
      Object.keys(defaultData).forEach((key) => {
        setValue(key as keyof CategoryFormData, defaultData[key]);
      });
    }
  }, [defaultData, setValue]);


  const onSubmit = async (data: CategoryFormData) => {
    try {
      setLoading(true);
      const payload = mockEditData(data);
      if (defaultData) {
        payload.assetId = undefined;
        delete payload.categories;
        delete payload.justificationCategoryId;
        delete payload.justificationText;
        const updatedPayload = getUpdatedFieldsValues(data, defaultData) as BaseCI;
        if (isEmpty(updatedPayload)) {
          throw new Error("No changes found")
        }
        if (updatedPayload.ciEffectiveDate) {
          updatedPayload.ciEffectiveDate = moment(updatedPayload.ciEffectiveDate).toISOString()
        }
        if (updatedPayload.publicDate) {
          updatedPayload.publicDate = moment(updatedPayload.publicDate).toISOString()
        }
        await putApiUpdateCI(defaultData.id, updatedPayload);
        dispatch(showSnackbar({ message: "CI Updated", type: "info" }));
      } else {
        await postApiCICreate(payload);
        dispatch(showSnackbar({ message: "CI Created", type: "info" }));
      }
      hideModal();
      if (onRefersh) onRefersh();
    } catch (err) {
      // @ts-ignore
      dispatch(showSnackbar({ message: err.message, type: "error" }));

    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    console.log("Close button clicked");
    hideModal();
  };

  const addApprover = (userIds: number | number[] | undefined) => {
    if (userIds && Array.isArray(userIds)) {
      setValue('approvers', userIds);
    } else {
      setValue('approvers', []);

    }
  }

  const addReviewer = (userIds: number | number[] | undefined) => {
    if (userIds && Array.isArray(userIds)) {

      setValue('reviewers', userIds);
    } else {
      setValue('reviewers', []);
    }
  }


  const filteredUsers = (selectedUsers: number[], users: InfoTenantUser[]) => {
    const allUsers = users.filter(u => selectedUsers.some(su => su === u.id));
    return allUsers
  }

  const selectedApprovers = watch('approvers');

  const selectedReviewers = watch('reviewers');

  return (
    <div style={{ width: "1000px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "7px" }}
      >
        <h2 style={{ margin: 0 }}>
          {defaultData ? `Edit CI` : `Add CI`}
        </h2>
      </Box>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 19,
          padding: "20px",
          paddingTop: '13px',
        }}
      >
        <Box display="flex" gap={2}>
          <FormControl error={!!errors.assetId} fullWidth>
            <Autocomplete
              options={assets}
              getOptionLabel={(option) => option.name}
              defaultValue={defaultData?.assetId || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Asset *"
                  variant="outlined"
                  error={!!errors.assetId}
                />
              )}
              {...register("assetId", { required: "Asset is required" })}
              onChange={(event, newValue) => setValue("assetId", newValue, { shouldValidate: true })}
              disabled={!!defaultData}
            />
          </FormControl>

          <FormControl error={!!errors.category} fullWidth>
            <Autocomplete
              options={categories}
              getOptionLabel={(option) => option.name}
              defaultValue={defaultData?.category || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category *"
                  variant="outlined"
                  error={!!errors.category}
                />
              )}
              disabled={!!defaultData}
              {...register("category", { required: "Category is required" })}
              onChange={(event, newValue) => setValue("category", newValue, { shouldValidate: true })}

            />
          </FormControl>
        </Box>
        <Box display="flex" gap={2}>
          <FormControl error={!!errors.justificationCategoryId} fullWidth>
            <Autocomplete
              options={justificationcategories}
              getOptionLabel={(option) => option.name}
              defaultValue={defaultData?.justificationCategoryId || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Justification Category/Code *"
                  variant="outlined"
                  error={!!errors.justificationCategoryId}
                />
              )}
              {...register("justificationCategoryId", { required: "Justification Category is required" })}
              onChange={(event, newValue) => setValue("justificationCategoryId", newValue, { shouldValidate: true })}
              disabled={!!defaultData}
            />
          </FormControl>
          <TextField
            label="Justification Text"
            variant="outlined"
            multiline
            {...register("justificationText")}
            error={!!errors.justificationText}
            helperText={errors.justificationText?.message}
            fullWidth
            disabled={!!defaultData}
          />
        </Box>
        <TextField
          label="Known CI *"
          variant="outlined"
          multiline
          rows={2}
          {...register("knownCI", { required: "Known CI is required" })}
          error={!!errors.knownCI}
          helperText={errors.knownCI?.message}
        />
        <Box display="flex" gap={2}>
          <TextField
            label="CI Effective Date *"
            variant="outlined"
            type="date"
            {...register("ciEffectiveDate", { required: "CI Effective Date is required" })}
            error={!!errors.ciEffectiveDate}
            helperText={errors.ciEffectiveDate?.message}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          <TextField
            label="Public Date"
            variant="outlined"
            type="date"
            {...register("publicDate")}
            error={!!errors.publicDate}
            helperText={errors.publicDate?.message}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
        <Box display="flex" sx={{ flexDirection: 'column' }} gap={2}>
          <div>
            <div style={{ fontWeight: "bold" }}>Reviewers</div>
            <div style={{ marginTop: "4px", display: "flex", justifyContent: "flex-start" }}>
              <UsersSelect
                allUsers={reviewers || []}
                selectedUsers={filteredUsers(selectedReviewers || [], reviewers || [])}
                maxAvatars={3}
                multiple={false}
                onChange={addReviewer}
                showAvatar={true}
                size={32}
              />
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Approvers</div>
            <div style={{ marginTop: "4px", display: "flex", justifyContent: "flex-start" }}>
              <UsersSelect
                allUsers={approvers || []}
                selectedUsers={filteredUsers(selectedApprovers || [], approvers || [])}
                maxAvatars={1}
                multiple={false}
                onChange={addApprover}
                showAvatar={true}
                size={32}
              />
            </div>
          </div>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Close
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {defaultData ? "Update" : "Add"}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default AddCIForm;
