import React, {useEffect, useState, useReducer, useContext, useRef} from 'react';
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import './TransformPanel.scss'
import WebViewerContext from "../../../contexts/webviewer-context";
import AnnotationFilterHelpers from "../AnnotationFilterHelpers";
import AnnotationFilter from "../AnnotationFilter";
import LoadTransformsButton from "./LoadTransformsButton";
import {loadRedactionAnnotations} from "../RedactionPanel/RedactionPanelContainer";
import { Menu, MenuItem } from '@mui/material';
import {useCustomModal} from "../../../pages/modals/custom-message-modal";
import TransformSettingsModal from "../TransformSettingsModal";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TransformIcon from '@mui/icons-material/Transform';
import {TransformTable} from "./transform-table";
import ReflowDropdown from "../ReflowDropdown/ReflowDropdown";
import {ReflowDropdownRef, ReflowDropdownState} from "../ReflowDropdown/withDropdown";

export const TransformPanel = (props:any) => {
    const { selectedTab } = props
    const { setInstance,instance } = useContext(WebViewerContext);
    const annotationManager = instance.Core.annotationManager;
    const { showModal, hideModal } = useCustomModal();
    const [redactionAnnotations, setRedactionsAnnotations] = useState<any[]>([])
    const [currentRedactions, setCurrentRedactions] = useState<any[]>([]);
    const [checkedRedactions, setCheckedRedactions] = useState<any[]>([]);
    const [selectedRedactions, setSelectedRedactions] = useState<any[]>([]);
    const [pageRangeFilter, setPageRangeFilter] = useState<number[]|undefined>(undefined);
    const [searchPhraseFilter, setSearchPhraseFilter] = useState<string|undefined>(undefined);
    const [categoryFilter, setCategoryFilter] = useState<string|undefined>(undefined);
    const [regexInputFilter, setRegexInputFilter] = useState<string|undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<'Asc'|'Desc'>("Asc")
    const [advancedOptionsAnchorEl, setAdvancedOptionsAnchorEl] = useState(null);
    const dispatch = useDispatch()
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const clearAllButtonClassName = classNames('clear-all-marked', { disabled: checkedRedactions.length === 0 });
    let currentRedactionsRef = useRef<any[]>([])
    let lastSelectedRedaction = useRef({})
    let pressedKeys=useRef<string[]>([]);
    const transformTabIndex = 2
    const reflowDropdownRef = useRef<ReflowDropdownRef | null>(null)
    function filterPageRangeOnChange(newPageRange: number[] | undefined) {
        //We need to clone the value so that React recognizes it as a change and re-renders.
        const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
        setPageRangeFilter(verifiedPageRange);
    }

    function searchPhraseOnChange(newSearchPhrase: string) {
        setSearchPhraseFilter(newSearchPhrase)
    }


    function regexInputOnChange(newRegexInput: string) {
        setRegexInputFilter(newRegexInput)
    }

    function sortChangeCallBack(newSortOrder: 'Asc'|'Desc') {
        setSortOrder(newSortOrder)
    }

    function categoryOnChange(newCategory: string) {
        setCategoryFilter(newCategory)
    }

    function clearReplacementText() {
        currentRedactions.forEach(redaction => {
            redaction.setCustomData("Replacement", "");
            redaction.setCustomData("ManuallyEdited", "");
        })
        uncheckAllRedactions();
    }

    function checkRedactions(redactions: any[]) {
        redactions.forEach(redaction => redaction.transformChecked=true)
        setCheckedRedactions(currentRedactionsRef.current.filter((redaction) => redaction.transformChecked===true))
    }

    function uncheckRedactions(redactions: any[]) {
        redactions.forEach(redaction => redaction.transformChecked=false)
        setCheckedRedactions(currentRedactionsRef.current.filter((redaction) => redaction.transformChecked===true))
    }

    function uncheckAllRedactions() {
        redactionAnnotations.forEach(redaction => redaction.transformChecked=false)
        setCheckedRedactions([])
    }

    function arrowHandler(increment: number) {
        let lastSelectedRedactionIndex = currentRedactionsRef.current.indexOf(lastSelectedRedaction.current)
        if (lastSelectedRedactionIndex>-1 && lastSelectedRedactionIndex<currentRedactionsRef.current.length) {
            selectAnnotation(currentRedactionsRef.current[lastSelectedRedactionIndex+ + increment])
            scrollIntoViewById(currentRedactionsRef.current[lastSelectedRedactionIndex + increment].Id)
        }
    }

    function onKeyUpHandler(event: any) {
        pressedKeys.current=pressedKeys.current.filter((key) => key!==event.key)
    }

    function onKeyDownHandler(event: any) {
        if (event.key === 'Shift' || event.key === 'Control' || event.key === 'Meta') {
            pressedKeys.current.push(event.key)
        }
        if (selectedTab.current===transformTabIndex) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
                arrowHandler(1)
            } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
                arrowHandler(-1)
            } else if (pressedKeys.current.includes('Meta') || pressedKeys.current.includes('Control')) {
                if (event.key === 'a') {
                    event.preventDefault()
                    annotationManager.selectAnnotations(currentRedactionsRef.current)
                } else if (event.key === 'c') {
                    checkRedactions(instance.Core.annotationManager.getSelectedAnnotations().filter((redaction:any) => redaction.elementName==='redact'))
                } else if (event.key === 'u') {
                    event.preventDefault()
                    uncheckRedactions(instance.Core.annotationManager.getSelectedAnnotations().filter((redaction:any) => redaction.elementName==='redact'))
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler)
        window.addEventListener('keyup', onKeyUpHandler)
        window.oncontextmenu = () => { pressedKeys.current=[] } // when the user right click, the onKeyUpHandler doesn't get triggered
        window.onblur = () => { pressedKeys.current=[] }
        // prevent memory leak
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
            window.removeEventListener('keyup', onKeyUpHandler);
            window.oncontextmenu=null
            window.onblur=null
        }
    }, [])

    useEffect(() => {
        const onAnnotationChanged = () => {
            const redactions = loadRedactionAnnotations(instance);
            setRedactionsAnnotations(redactions);
            setCheckedRedactions(redactions.filter((redaction: any) => redaction.transformChecked))
        }

        const onTransformLoaded = () => {
            onAnnotationChanged();
            forceUpdate()
        }

        const onAnnotationSelected = (annotations: any) => {
            if (annotations[annotations.length-1].elementName==='redact') {
                lastSelectedRedaction.current = annotations[annotations.length - 1]
            }
            setSelectedRedactions(instance.Core.annotationManager.getSelectedAnnotations().filter((redaction:any) => redaction.elementName==='redact'))
        }

        if (instance) {
            const annotationManager = instance.Core.annotationManager;
            setRedactionsAnnotations(loadRedactionAnnotations(instance))
            annotationManager.addEventListener('annotationChanged', onAnnotationChanged)
            annotationManager.addEventListener('transformsLoaded', onTransformLoaded)
            annotationManager.addEventListener('annotationSelected', onAnnotationSelected);
        }

        return () => {
            if (instance) {
                instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged)
                instance.Core.annotationManager.removeEventListener('annotationChanged', onTransformLoaded)
                instance.Core.annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
            }
        }
    }, [instance]);

    useEffect(() => {
        //We need to map these fields to the ones that are used in the filter helper methods.
        redactionAnnotations.forEach((annotation: any) => {
            annotation.pageNum = annotation.PageNumber
        })
        AnnotationFilterHelpers.applyFiltersTransform(redactionAnnotations, searchPhraseFilter, pageRangeFilter, categoryFilter, regexInputFilter)

        const sortedRedactions = redactionAnnotations.sort((a: any, b: any) => {
            if (sortOrder==="Asc") {
                //sorting by location in document then location in page
                if (a.pageNum !== b.pageNum) {
                    return a.pageNum - b.pageNum
                } else if (a.getY() !== b.getY()) {
                    return a.getY() - b.getY()
                } else {
                    return a.getX() - b.getX()
                }
            } else {//desc
                if (b.pageNum !== a.pageNum) {
                    return b.pageNum - a.pageNum
                } else if (b.getY() !== a.getY()) {
                    return b.getY() - a.getY()
                } else {
                    return b.getX() - a.getX()
                }
            }
        })
        const filteredRedactions: any[] = [];
        sortedRedactions.forEach((annotation: any) => {

            if (annotation.shouldHide) {
                return
            }
            filteredRedactions.push(annotation);
        });

        setCurrentRedactions(filteredRedactions);
        currentRedactionsRef.current = filteredRedactions
    }, [redactionAnnotations, pageRangeFilter, searchPhraseFilter, categoryFilter, regexInputFilter, sortOrder]);

    const scrollIntoViewById = (id: any) => {
        if (id) {
            const el = document.querySelector('#annotation-' + id);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const onClickCheckbox = (annotation: any) => {
        if (checkedRedactions.some(selectedRedaction => selectedRedaction===annotation)) {
            setCheckedRedactions(prevState => prevState.filter((el) => el!==annotation));
            annotation.transformChecked = false;
        } else {
            setCheckedRedactions([...checkedRedactions, annotation]);
            annotation.transformChecked = true;
        }
    }

    const onChangeComplete = (annotation: any) => {
        annotationManager.trigger('annotationChanged', [annotation], 'replacementChange', {imported: false})//This trigger will notify the webviewer
        //that there have been changes to the document and hence it will keep track of those changes if the user switched tabs in the webviewer
        forceUpdate()
    }

    const onReplacementChange = (annotation: any, event: any) => {
        const value = event.target.value;
        annotation.setCustomData("Replacement", event.target.value);
        annotation.setCustomData("ManuallyEdited", value ? "true" : "");
    }

    function handleCloseAdvancedOptionsMenu() {
        setAdvancedOptionsAnchorEl(null);
    };

    function handleChangeCategory() {
        handleCloseAdvancedOptionsMenu();
        instance.Core.annotationManager.trigger('transformChangeCategory', {annotations: checkedRedactions})
    }

    function selectAnnotation(annotation: any) {
        if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
            annotationManager.deselectAllAnnotations()
        }
        instance.Core.annotationManager.selectAnnotation(annotation);
        instance.Core.annotationManager.jumpToAnnotation(annotation);
    }

    const handleAdvanceOptionsClick = (event: any) => {
        setAdvancedOptionsAnchorEl(event.target);
    };

    const renderRows = () => {
        return <TransformTable currentAnnotations={currentRedactions}
                               selectedAnnotations={selectedRedactions}
                               checkedAnnotations={checkedRedactions}
                               onClickCheckbox={onClickCheckbox}
                               selectAnnotation={selectAnnotation}
                               onReplacementChange={onReplacementChange}
                               onChangeComplete={onChangeComplete}
                               allowEdit={true}/>
    };

    const noRedactionAnnotations = (
        <div className="no-marked-redactions">
            <div style={{display: "flex", flexDirection: "row"}}>
                <TipsAndUpdatesIcon color={"secondary"}/>
                <div className="msg" style={{marginLeft: 20}}>Start by searching for sensitive information. Then, mark results to prepare them for transformation.</div>
            </div>
            <TransformIcon className={"transform-icon"} color={"secondary"}/>
        </div>
    );

    const redactAllButtonClassName = classNames('redact-all-marked', {disabled: redactionAnnotations.length === 0});


    const applyCheckedRedactions = () => {
        if(reflowDropdownRef.current) {
            const reflowType = reflowDropdownRef.current.setData();
            //instance.Core.documentViewer.trigger('processStarted')
            setTimeout(() => {
                redactionAnnotations.forEach(redaction => redaction.transformChecked = false)
                checkedRedactions.forEach(redaction => {
                    redaction.pageNum = redaction.PageNumber
                    redaction.transformChecked = true
                })
                if(reflowType === ReflowDropdownState.TEXT_REFLOW_DEFAULT) {
                    instance.Core.annotationManager.trigger('transformText');
                }else{
                    instance.Core.annotationManager.trigger('transformText-with-fit');
                }
            }, 10);
        }
    };


    return <div className={'panel'}>
        <div className="d-flex justify-content-between" style={{alignItems: "center",marginBottom: '10px'}}>
        {redactionAnnotations.length > 0 ?
            <>
                <div className="extra-options" style={{marginTop: 0}}>
                <button className='Button' onClick={handleAdvanceOptionsClick}>Advanced Options
                    {advancedOptionsAnchorEl ? "  ↑" : "  ↓"}</button>
            </div>
                <ReflowDropdown ref={reflowDropdownRef}/>
            </> : null}

        </div>
        <Menu
            id="simple-menu2"
            keepMounted
            anchorEl={advancedOptionsAnchorEl}
            open={Boolean(advancedOptionsAnchorEl)}
            onClose={handleCloseAdvancedOptionsMenu}
            style={{zIndex: 99999}}
        >
            <MenuItem disabled={checkedRedactions.length === 0} onClick={handleChangeCategory}>Change
                Category</MenuItem>
            <LoadTransformsButton closeMenuCallBackFunction={handleCloseAdvancedOptionsMenu}/>
            <MenuItem onClick={() => showModal(TransformSettingsModal, {})}>Settings</MenuItem>
        </Menu>

        {redactionAnnotations.length > 0 ? <AnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange}
                                                             searchPhraseChangeCallBack={searchPhraseOnChange}
                                                             categoryChangeCallBack={categoryOnChange}
                                                             categories={AnnotationFilterHelpers.getCategories(redactionAnnotations)}
                                                             sortChangeCallBack={sortChangeCallBack}
                                                             regexInputChangeCallBack={regexInputOnChange}
                                                             limitedOptions={false}
                                                             includeSelection={true}
                                                             selectAllCallBack={() => checkRedactions(currentRedactions)}
                                                             unselectAllCallBack={uncheckAllRedactions}/> : null}
        {redactionAnnotations.length > 0 ? renderRows() : noRedactionAnnotations}
        {redactionAnnotations.length > 0 ? <div className="redaction-panel-controls-bottom">
            <button className={clearAllButtonClassName}
                    onClick={clearReplacementText}
            >
            {'Clear'}
            </button>
            <button
                disabled={checkedRedactions.length === 0}
                className={redactAllButtonClassName}
                onClick={applyCheckedRedactions}
                aria-label={"Redact"}
            >
                Apply
            </button>
        </div> : null}
    </div>;
};


const mapStateToProps = (state: any) => ({
    style: state.viewer.activeToolStyles
});

export const formatCategoricalTransform = (category: string) => {
    category = category.replaceAll('_', ' ');
    return `(${category})`
}

function startsWithAndEndsWithParentheses(input: string): boolean {
    const regex = /^\(.*\)$/;
    return regex.test(input);
}

export const getTransformStyle = (annotation: any, panel: string) => {
    const replacementText = annotation.getCustomData('Replacement');
    const redact = replacementText === '%REDACT%' && panel === 'transform';
    const retain = replacementText === '%RETAIN%';
    const manuallyEdited = annotation.getCustomData("ManuallyEdited");
    const category = AnnotationFilterHelpers.getCategory(annotation)
    const catTrans = category && (replacementText === '*' || startsWithAndEndsWithParentheses(replacementText));
    const backgroundColor = manuallyEdited ? 'yellow' : ((redact || catTrans) ? 'blue' : retain ? 'green' : 'inherit');
    const textColor = (redact || retain || catTrans && !manuallyEdited) ? 'white' : 'inherit';
    const displayReplacementText = redact || retain ? replacementText.substring(1, replacementText.length - 1) : replacementText;
    return { backgroundColor, textColor, displayReplacementText };
};