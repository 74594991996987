import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../pages.scss";
import { Notification } from "../../models/Notification";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hook";
import { markAllNotificationsViewed, notificationsSelector, removeNotification } from "../../redux/notifications";
import {
    convertDateTimeFromUTCToTimezone,
    getProjectByTaskId,
    getTaskListByTaskId,
    getUserInitials
} from "../../services/utils";
import { loadedProjectsSelector, projectsSelector } from "../../redux/projects";
import { Project } from "../../models/Project";
import { TaskList } from "../../models/TaskList";
import { deleteApiNotificationsById, putApiNotifications } from "../../services/notifications";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { fetchCustomFields, fetchProjects, fetchTemplates, fetchUsers } from "../../services/init";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { blueIconColor, statusList, StatusType } from "../../constants";
import { hideProgressLine, showProgressLine } from "../../redux/progress-line";
import { showSnackbar } from "../../redux/snackbar";
import { Avatar } from "@mui/material";
import { isToday } from "date-fns";
import moment from "moment";
import { userSettingsSelector } from "../../redux/userSettings";
import { usersSelector } from "../../redux/users";
import { customFieldsSelector } from "../../redux/custom-fields";
import { TaskLinkListener } from "../../components/project/comments";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useAuthService } from "../../contexts/auth-context";
import {InfoTenantUser} from "../../models/InfoTenantUser";
import {UserSettings} from "../../models/UserSettings";
import { ciWorkFlowMessage, ciWorkFlowStatus, CIWorkFlowStatusType } from "../../models/ciWorkFlowStatus";

type GroupedNotification = {
    taskId: number | undefined,
    notifications: Notification[],
    type: string
}

export function Notifications() {

    const auth = useAuthService();
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const notifications: Notification[] = useAppSelector((state) => notificationsSelector(state));
    const projectsLoaded = useAppSelector((state) => loadedProjectsSelector(state));
    const initProjects = useRef<Project[]>([]);
    const totalProjectsIds = useRef<number[] | null>(null);

    const groupNotifications = (notifications: Notification[]) => {
        const groupedNotifications: GroupedNotification[] = [];
        notifications.forEach(notification => {
            if (notification.taskId && groupedNotifications.length > 0 &&
                groupedNotifications[groupedNotifications.length - 1].taskId === notification.taskId) {
                groupedNotifications[groupedNotifications.length - 1] = {
                    ...groupedNotifications[groupedNotifications.length - 1],
                    notifications: [...(groupedNotifications[groupedNotifications.length - 1].notifications), notification]
                };
            } else {
                groupedNotifications.push({ taskId: notification.taskId, notifications: [notification], type: notification.type });
            }
        });
        return groupedNotifications;
    }

    useEffect(() => {
        const controller = new AbortController();
        if (!projectsLoaded) {
            fetchProjects(controller.signal, dispatch, totalProjectsIds.current, initProjects.current);
            if (auth.hasPMRoleAccess()) {
                fetchTemplates(controller.signal, dispatch);
            }
            fetchUsers(controller.signal, dispatch);
            fetchCustomFields(controller.signal, dispatch);
        }
        return () => controller.abort()
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const notificationsIds = notifications.filter(n => !n.viewed).map(n => n.id);
        if (notificationsIds.length > 0) {
            totalProjectsIds.current = []
            initProjects.current = []
            putApiNotifications(notificationsIds, controller.signal)
                .then(() => dispatch(markAllNotificationsViewed()));
        }
        return () => controller.abort()
    }, [notifications]);

    if (auth.loginInfo?.tenant?.user?.roles?.length===1 && auth.loginInfo?.tenant?.user?.roles[0]==="VIEW") return <div className='page'></div>;
    return <div className='page-wrapper' style={{ paddingTop: 24 }}>
            <div className='page-header'>
                <ArrowBack className='arrow-back' onClick={() => nav(-1)} /> Notifications
            </div>
            {notifications.length === 0 ? <div style={{ margin: 32, backgroundColor: "var(--lightGrayishBlue)",
                    display: "flex", flexDirection: "column", alignItems: "center", height: 205 }}>
                    <div style={{ marginTop: 32, marginBottom: 40, textAlign: "center",
                        fontSize: 21, fontWeight: 600, letterSpacing: 0.15}}>
                        No new notifications
                    </div>
                </div> :
                <div style={{display: 'flex', flexDirection: "column"}}>
                    {groupNotifications(notifications).map((group, index) =>
                        group.taskId ? <NotificationItemGroup key={`${new Date().getTime()}-${index}`} group={group}/> :
                            <NotificationComponent key={group.notifications[0].id} notification={group.notifications[0]}/>
                    )}
                </div>
            }
        </div>
}

function getNotificationDateFormat(date: string, timezone: string, format: string) {
    const userDate = convertDateTimeFromUTCToTimezone(date, timezone, format);
    return isToday(new Date(date)) ?
        moment(date).add(-5, "s").fromNow() : moment(userDate).format('MMMM DD [at] h:mm A');
}

function NotificationComponent(p: {notification: Notification}) {
    const dispatch = useAppDispatch();
    const users = useAppSelector((state) => usersSelector(state, true));
    const actionUser = users.find(user => user.id === p.notification.actionUserId);
    const userSettings = useAppSelector((state) => userSettingsSelector(state));


    const deleteNotification = (id: number) => {
        dispatch(showProgressLine())
        deleteApiNotificationsById(id)
            .then(() => {
                dispatch(removeNotification(id));
                dispatch(hideProgressLine());
            })
            .catch(() => {
                dispatch(hideProgressLine());
                dispatch(showSnackbar({ message: "Error dismissing notification!", type: "error" }));
            })
    }

    if (p.notification.type === 'editPatterns') {
        return <PatternEditNotification notification={p.notification} actionUser={actionUser} deleteNotification={deleteNotification} userSettings={userSettings}/>
    }else if(p.notification.type === 'ciWorkflow'){
        return <CINotification notification={p.notification} actionUser={actionUser} deleteNotification={deleteNotification} userSettings={userSettings}/>
    } else {
        return <ProjectShareNotification notification={p.notification} actionUser={actionUser} deleteNotification={deleteNotification} userSettings={userSettings}/>
    }
}

function PatternEditNotification(p: { notification: Notification, actionUser: InfoTenantUser|undefined, deleteNotification:(id:number) => void, userSettings: UserSettings}) {
    return <div style={{ display: "flex", flexDirection: "column",
        border: "1px solid #E6E7E8", borderRadius: 4, marginBottom: 24 }}>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "16px 24px 0 24px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontSize: 11, letterSpacing: 1.5, textTransform: "uppercase" }}>
                    PATTERNS EDITED
                </div>
            </div>
            <CheckCircleRoundedIcon sx={{ color: blueIconColor, cursor: "pointer" }}
                                    onClick={() => p.deleteNotification(p.notification.id)} />
        </div>
        <div style={{ backgroundColor: "#F5F6F7", display: "flex", flexDirection: "column", padding: "16px 24px" }}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div style={{ display: "flex", alignItems: "center", fontSize: 17, letterSpacing: 0.49 }}>
                    <Avatar sx={{ backgroundColor: p.actionUser?.color ?? "#D06959", color: "#FFFFFF" }}>
                        {getUserInitials(p.actionUser?.name)}
                    </Avatar>
                    <div style={{ marginLeft: 16, display: "flex", alignItems: "center" }}>
                        <span>{p.notification.content}</span>
                    </div>
                </div>
                <span>{getNotificationDateFormat(p.notification.createdAt, p.userSettings.regional.timezone,
                    "YYYY-MM-DD HH:mm:ss")}</span>
            </div>
        </div>
    </div>
}

function ProjectShareNotification(p: { notification: Notification, actionUser: InfoTenantUser|undefined, deleteNotification:(id:number) => void, userSettings: UserSettings}) {
    const nav = useNavigate();
    const [ type, projectId, ...projectName ] = p.notification.content!.split("-");
    const goToProject = () => nav(`/app/user/workflow/projects/${projectId}`)

    return <div style={{ display: "flex", flexDirection: "column",
        border: "1px solid #E6E7E8", borderRadius: 4, marginBottom: 24 }}>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "16px 24px 0 24px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ fontSize: 11, letterSpacing: 1.5, textTransform: "uppercase" }}>
                    {type === "added" ? "YOU NOW HAVE ACCESS TO" : "YOU NO LONGER HAVE ACCESS TO"}
                </div>
                <span style={{ fontSize: 21, fontWeight: 600, letterSpacing: 0.15, marginTop: 8,
                    marginBottom: 16 }}>
                    {projectName.join("-")}
                </span>
            </div>
            <CheckCircleRoundedIcon sx={{ color: blueIconColor, cursor: "pointer" }}
                                    onClick={() => p.deleteNotification(p.notification.id)} />
        </div>
        <div style={{ backgroundColor: type === "added" ? "#F5F6F7" : "#FFEFED", display: "flex", flexDirection: "column", padding: "16px 24px" }}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <div style={{ display: "flex", alignItems: "center", fontSize: 17, letterSpacing: 0.49 }}>
                    <Avatar sx={{ backgroundColor: p.actionUser?.color ?? "#D06959", color: "#FFFFFF" }}>
                        {getUserInitials(p.actionUser?.name)}
                    </Avatar>
                    <div style={{ marginLeft: 16, display: "flex", alignItems: "center" }}>
                        <span style={{ fontWeight: 600, marginRight: 8 }}>{p.actionUser?.name} </span>
                        {type === "added" ?
                            <span>has shared project <span style={{ color: "#217DA2", cursor: "pointer" }}
                                                           onClick={goToProject}>{projectName.join("-")}</span> with
                                <span style={{ color: "#217DA2" }}> You</span></span> :
                            <span>has restricted your access to {projectName.join("-")}</span>
                        }
                    </div>
                </div>
                <span>{getNotificationDateFormat(p.notification.createdAt, p.userSettings.regional.timezone,
                    "YYYY-MM-DD HH:mm:ss")}</span>
            </div>
        </div>
    </div>
}

function CINotification(p: { notification: Notification, actionUser: InfoTenantUser | undefined, deleteNotification: (id: number) => void, userSettings: UserSettings }) {
    const nav = useNavigate();
    const [workflowStatusString, ciId, assetName, categoryName, ciEffectiveDate] = p.notification.content!.split("-");
    const workflowStatus = Number(workflowStatusString) as CIWorkFlowStatusType;
    const statusDetails = ciWorkFlowStatus[workflowStatus];
    const goToCI = () => nav(`/app/user/cim/ci-workflow?ciId=${ciId}`);

    return (
        <div style={{ display: "flex", flexDirection: "column", border: "1px solid #E6E7E8", borderRadius: 4, marginBottom: 24 }}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "16px 24px 0 24px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: 11, letterSpacing: 1.5, textTransform: "uppercase" }}>
                        {statusDetails.label}
                    </div>
                    <span style={{ fontSize: 21, fontWeight: 600, letterSpacing: 0.15, marginTop: 8, marginBottom: 16 }}>
                        {`${assetName}-${categoryName}-${ciEffectiveDate}`}
                    </span>
                </div>
                <CheckCircleRoundedIcon sx={{ color: blueIconColor, cursor: "pointer" }} onClick={() => p.deleteNotification(p.notification.id)} />
            </div>
            <div style={{ backgroundColor: [2, 4, 6, 8].includes(workflowStatus) ? "#F5F6F7" : "#FFEFED", display: "flex", flexDirection: "column", padding: "16px 24px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", fontSize: 17, letterSpacing: 0.49 }}>
                        <Avatar sx={{ backgroundColor: p.actionUser?.color ?? "#D06959", color: "#FFFFFF" }}>
                            {getUserInitials(p.actionUser?.name)}
                        </Avatar>
                        <div style={{ marginLeft: 16, display: "flex", alignItems: "center" }}>
                            <span>
                                {ciWorkFlowMessage[workflowStatus](p.actionUser)}
                                {[2, 4, 6, 8].includes(workflowStatus) && <span style={{ color: "#217DA2", cursor: "pointer" }} onClick={goToCI}> {ciId}</span>}
                            </span>
                        </div>
                    </div>
                    <span>
                        {getNotificationDateFormat(p.notification.createdAt, p.userSettings.regional.timezone, "YYYY-MM-DD HH:mm:ss")}
                    </span>
                </div>
            </div>
        </div>
    );
}

function NotificationItemGroup(p:{ group: GroupedNotification }) {

    const dispatch = useAppDispatch();
    const nav = useNavigate();

    const [project, setProject] = useState<Project>()
    const [taskList, setTaskList] = useState<TaskList>();

    const projects = useAppSelector((state) => projectsSelector(state));

    useEffect(() => {
        let p2;
        if (project === undefined) {
            p2 = getProjectByTaskId(projects, p.group.taskId!) as Project;
            setProject(p2);
        }
        if (taskList === undefined && (p2 !== undefined || project !== undefined)
            && ((p2 && p2.taskLists) || (project && project.taskLists))) {
            const tl = getTaskListByTaskId(p2 !== undefined ? p2 : project!, p.group.taskId!);
            if (tl) {
                setTaskList(tl);
            }
        }
    }, [projects, project]);

    const deleteNotification = (ids: number[]) => {
        ids.forEach(id => {
            dispatch(showProgressLine())
            deleteApiNotificationsById(id)
                .then(() => {
                    dispatch(removeNotification(id));
                    dispatch(hideProgressLine());
                })
                .catch(() => {
                    dispatch(hideProgressLine());
                    dispatch(showSnackbar({ message: "Error dismissing notification!", type: "error" }));
                })
        })
    }

    const getTaskName = (taskId: number) => {
        const task = taskList?.tasks!.find(task => task.id === taskId ||
            (task.subtasks && task.subtasks.find(s => s.id === taskId)));
        const subtask = task!.id !== taskId ? task!.subtasks!.find(s => s.id === taskId) : undefined;
        return subtask ? <>
                <span style={{ fontSize: 15, fontWeight: 600, letterSpacing: 0.24, marginTop: 8, cursor: "pointer" }}
                      onClick={() => nav(`/app/user/workflow/task-details/${task!.id}`)}>
                    {task!.name}
                </span>
                <div style={{ fontSize: 21, fontWeight: 600, letterSpacing: 0.15, marginTop: 8,
                    marginBottom: 16, cursor: "pointer", display: "flex", alignItems: "center" }}
                     onClick={() => nav(`/app/user/workflow/task-details/${task!.id}/${subtask.id}`)}>
                    <SubdirectoryArrowRightIcon /><span style={{ marginLeft: 8 }}>{subtask.name}</span>
                </div>
            </> :
            <span style={{ fontSize: 21, fontWeight: 600, letterSpacing: 0.15, marginTop: 8,
                marginBottom: 16, cursor: "pointer" }}
                  onClick={() => nav(`/app/user/workflow/task-details/${task!.id !== taskId ? task!.id + '/' : ''}${taskId}`)}>
                {task!.name}
            </span>
    }

    const isSubtask = (taskId: number) => !taskList?.tasks!.find(task => task.id === taskId)

    return project === undefined || taskList === undefined ? <></> :
        <div style={{ display: "flex", flexDirection: "column",
            border: "1px solid #E6E7E8", borderRadius: 4, marginBottom: 24 }} key={project?.id}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "16px 24px 0 24px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontSize: 11, letterSpacing: 1.5, textTransform: "uppercase" }}>
                        <span style={{ cursor: "pointer" }}
                              onClick={() => nav(`/app/user/workflow/projects/${project?.id}`)}>
                            {project?.name}
                        </span>
                        <ArrowForwardIosIcon style={{ alignSelf: "center" }}
                                             sx={{ fontSize: 11, color: "gray", margin: "0px 6px -2px 6px" }} />
                        <span style={{ cursor: "pointer" }}
                              onClick={() => nav(`/app/user/workflow/projects/${project?.id}/task-list/${taskList?.id}`)}>
                            {taskList?.name}
                        </span>
                    </div>
                    {getTaskName(p.group.taskId!)}
                </div>
                <CheckCircleRoundedIcon sx={{ color: blueIconColor, cursor: "pointer" }}
                                        onClick={() => deleteNotification(p.group.notifications.map(n => n.id))} />
            </div>
            {p.group.notifications.map(notification => <NotificationItem key={notification.id}
                                                                         notification={notification}
                                                                         isSubtask={isSubtask(notification.taskId!)} />)}
        </div>
}

function NotificationItem(p: { notification: Notification, isSubtask: boolean }) {
    const users = useAppSelector((state) => usersSelector(state, true));
    const actionUser = users.find(user => user.id === p.notification.actionUserId);
    const userSettings = useAppSelector((state) => userSettingsSelector(state));

    return <div style={{ backgroundColor: p.notification.type === "overdue" ?
            (+p.notification.content! < 0 ? "#FFEFED" : "#FFEFD0") : "#F5F6F7",
        display: "flex", flexDirection: "column", padding: "16px 24px" }}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
            <div style={{ display: "flex", alignItems: "center", fontSize: 17, letterSpacing: 0.49 }}>
                {p.notification.type === "overdue" ?
                    <>
                    {/* check this, what happens when notifications are overdue */}
                        {+p.notification.content! < 0 && <span style={{color: "#C13826", fontWeight: 600 }}>OVERDUE</span>}
                        <div style={{ marginLeft: 8, fontWeight: 600 }}>
                            {+p.notification.content! < 0 && `Due date was on ${moment(p.notification.content, "MMM DD YYYY").format("MMM DD")}`}
                            {`${p.isSubtask ? 'Subtask' : 'Task'} is due ${p.notification.content === "2 days" || 
                            p.notification.content === "3 days" ? `in ${p.notification.content}` : p.notification.content}`}
                        </div>
                    </>
                    :
                    <>
                        <Avatar sx={{backgroundColor: actionUser?.color ?? "#D06959", color: "#FFFFFF"}}>
                            {getUserInitials(actionUser?.name)}
                        </Avatar>
                        <div style={{ marginLeft: 16, display: "flex", alignItems: "center" }}>
                            <span style={{fontWeight: 600, marginRight: 8}}>{actionUser?.name}</span>
                            <NotificationItemAction notification={p.notification}/>
                        </div>
                    </>
                }
            </div>
            <span>{getNotificationDateFormat(p.notification.createdAt, userSettings.regional.timezone,
                "YYYY-MM-DD HH:mm:ss")}</span>
        </div>
        {p.notification.type === "comment" &&
            <div style={{ backgroundColor: "#FFFFFF", marginTop: 8, padding: "8px 16px", marginLeft: 56 }}
                 dangerouslySetInnerHTML={{ __html: p.notification.content! }} />}
    </div>

}

function NotificationItemAction(p: { notification: Notification }) {

    const nav = useNavigate();
    const customFields = useAppSelector((state) => customFieldsSelector(state));
    const listeners = useRef<TaskLinkListener[]>([])

    useEffect(() => {
        if (p.notification.type === "comment") {
            p.notification.content!.match(/"link-[\d.]+-\d+"/g)?.forEach(match => {
                const link = match.replaceAll("\"", "")
                const taskId = +link.split("-")[2];
                const el = document.getElementById(link);
                if (el) {
                    el.addEventListener('click', () => nav(`/app/user/workflow/task-details/${taskId}`));
                    listeners.current.push({id: link, taskId});
                }
            })
        }
        return () => {
            listeners.current.forEach(listener => {
                const el = document.getElementById(listener.id);
                if (el) {
                    el.removeEventListener('click', () => nav(`/app/user/workflow/task-details/${listener.taskId}`));
                }
            });
        };
    }, []);


    const getSubtask = (subtask: string) => {
        const [ id, ...name ] = subtask.split("->");
        return {
            id,
            name: name.join("->")
        }
    }

    const getCustomField = (content: string) => {
        const [ id, ...value ] = content.split("->");
        const name = customFields.find(cf => cf.id === +id)!.name
        return {
            name,
            value: value.join("->")
        }
    }

    return customFields.length > 0 ? <>
        {p.notification.type === "assign" && <span>assigned to <span style={{ color: "#217DA2" }}>You</span></span>}
        {p.notification.type === "setDueDate" && <span>set due date to <span>
            {moment(p.notification.content, "MMM DD YYYY").format("MMM DD")}</span></span>}
        {p.notification.type === "dueDate" && <span>changed due date to <span>
            {moment(p.notification.content, "MMM DD YYYY").format("MMM DD")}</span></span>}
        {p.notification.type === "removeDueDate" && <span>removed due date</span>}
        {p.notification.type === "setStartDate" && <span>set start date to <span>
            {moment(p.notification.content, "MMM DD YYYY").format("MMM DD")}</span></span>}
        {p.notification.type === "startDate" && <span>changed start date to <span>
            {moment(p.notification.content, "MMM DD YYYY").format("MMM DD")}</span></span>}
        {p.notification.type === "removeStartDate" && <span>removed start date</span>}
        {p.notification.type === "status" && <div style={{ display: "flex", alignItems: "center" }}>changed status
            <NotificationStatus status={p.notification.content!} /></div>}
        {p.notification.type === "subtask" && <span>added <span style={{ fontWeight: 600 }}>subtask</span>
            &nbsp;
            <span style={{ cursor: "pointer", color: "#217DA2"}}
                  onClick={() => nav(`/app/user/workflow/task-details/${p.notification.taskId}/${getSubtask(p.notification.content!).id}`)}>
            {getSubtask(p.notification.content!).name}
                </span>
                </span>}
        {p.notification.type === "customField" && <span>set custom field <span style={{ fontWeight: 600 }}>
            {getCustomField(p.notification.content!)?.name}</span> to <span style={{ fontWeight: 600 }}>
            {getCustomField(p.notification.content!)?.value}</span></span>}
        {p.notification.type === "comment" && <span>commented: </span>}
    </> : <></>
}

function NotificationStatus(p: { status: string }) {
    const [ from, to ] = p.status.split("->");

    return <div style={{ display: "flex", alignItems: "center", marginLeft: 4 }}>
        from <StatusBox statusValue={from as StatusType} /> to <StatusBox statusValue={to as StatusType} />
    </div>
}

function StatusBox(p: { statusValue: StatusType}) {
    return <div style={{ width: 86, height: 32, color: "#FFFFFF", fontSize: 11, fontWeight: 600, letterSpacing: 0.33,
        display: "flex", justifyContent: "center", alignItems: "center" , marginLeft: 4, marginRight: 4,
        backgroundColor: statusList[p.statusValue].color}}>
        {statusList[p.statusValue].name}
    </div>
}
