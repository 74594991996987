import React from "react";
import { Box, Card, Typography, Tooltip } from "@mui/material";
import { CI } from "../../../../models/CI";
import { WorkFlowLabel } from "../component/workFlowLabel";

type CategoryListProps = {
  items: CI[];
  onItemClick: (item: CI) => void;
};

const Chip = (p: { label?: string }) => (
  <div
    style={{
      backgroundColor: "#e9eef9",
      paddingLeft: ".25rem",
      paddingRight: ".25rem",
      width: "fit-content",
      borderRadius: 3,
    }}
  >
    <Typography style={{ fontSize: 15 }}>{p?.label || ""}</Typography>
  </div>
);

export const CategoryList: React.FC<CategoryListProps> = ({ items, onItemClick }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {items.map((item) => (
          <Card
            key={item.id}
            sx={{
              padding: "16px 24px",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              border: "1px solid #e0e0e0",
              cursor: "pointer",
            }}
            onClick={() =>
              onItemClick(item)
            }
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "150px 150px 1fr",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography sx={{ fontSize: "0.875rem", opacity: 0.7 }}>
                  Asset
                </Typography>
                <Chip label={item.asset.name} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography sx={{ fontSize: "0.875rem", opacity: 0.7 }}>
                  Category
                </Typography>
                <Chip label={item?.category?.name} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Typography sx={{ fontSize: "0.875rem", opacity: 0.7 }}>
                  Known CI
                </Typography>
                <Tooltip title={item.knownCI || ""} arrow>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: "0.875rem",
                      maxWidth: "400px",
                    }}
                  >
                    {item.knownCI}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ marginTop: '8px' }}>
              <WorkFlowLabel
                currentStatus={item.status}
                wrapperStyle={{ width: "248px", pag: "1px" }}
                labelStyle={{ fontSize: "8px" }}
              />
            </Box>
          </Card>
        ))}

        {!items.length && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "16px",
              borderRadius: "8px",
              border: "1px dashed #e0e0e0",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Typography sx={{ fontSize: "1rem", color: "#9e9e9e" }}>
              No CI
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
