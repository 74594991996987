import { Box, Button, MenuItem, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UsersAvatarList } from "../../../../components/users-avatar-list";
import { UsersSelect } from "../../../../components/users-select";
import { useAuthService } from "../../../../contexts/auth-context";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hook";
import { useCimUserRoles } from "../../../../hooks/useCimUserRoles";
import { assestStatus } from "../../../../models/assetStatus";
import { InfoTenantUser } from "../../../../models/InfoTenantUser";
import { showSnackbar } from "../../../../redux/snackbar";
import { usersSelector } from "../../../../redux/users";
import { postApiAssetsCreate, putApiUpdateAsset } from "../../../../services/assets";
import { getUpdatedFieldsValues } from "../../../../tools/react-from";
import AssetStatus from "./assetStatus";

interface assetFormData {
  id: string;
  name: string;
  genericName: string;
  description: string;
  status: string;
  moreInformationUrl: string;
  assignees: number[];
}


const mockAirtableCreateData = (data: any) => {
  const mockAssetRecord = {
    id: data.id,
    name: data.name,
    description: data.description,
    status: data.status,
    genericName: data.genericName,
    moreInformationUrl: data.moreInformationUrl,
    assignees: data.assignees
  };
  return mockAssetRecord;
};
const AddAssetForm: React.FC = (props: any) => {
  const { hideModal, onRefersh, defaultData } = props;
  const users = useAppSelector((state) => usersSelector(state, false))

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm<assetFormData>();
  const dispatch = useAppDispatch();
  const auth = useAuthService();
  const { isManager } = useCimUserRoles();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultData && Object.keys(defaultData).length > 0) {
      Object.keys(defaultData).forEach((key) => {
        setValue(key as keyof assetFormData, defaultData[key]);
      });
    } else {
      if (isManager) {
        setValue("assignees", auth?.loginInfo?.tenant?.user?.id ? [auth?.loginInfo?.tenant?.user.id] : []);
      }
    }
  }, [defaultData, isManager, auth?.loginInfo?.user?.id]);

  const selectedAssignees = watch('assignees');
  const onSubmit = async (data: assetFormData) => {
    if (!data?.assignees?.length) {
      setError("assignees", { message: "Owner is required" })
      return;
    }
    try {
      setLoading(true);
      const payload = mockAirtableCreateData(data);
      delete payload.id;
      if (defaultData) {
        const updatedPayload = getUpdatedFieldsValues(payload, defaultData);
        if (isEmpty(updatedPayload)) {
          throw new Error("No changes found")
        }
        await putApiUpdateAsset(defaultData.id, updatedPayload);
        dispatch(showSnackbar({ message: "Assets Updated", type: "info" }));
      } else {
        await postApiAssetsCreate(payload)
        dispatch(showSnackbar({ message: "Assets Created", type: "info" }));
      }
      onRefersh();
      hideModal();
    } catch (err) {
      // @ts-ignore
      dispatch(showSnackbar({ message: err.message, type: "error" }));

    } finally {
      setLoading(false);
    }

  };
  const handleClose = () => {
    console.log("Close button clicked");
    hideModal();
  };

  const addUser = (userIds: number | number[] | undefined) => {
    if (userIds && Array.isArray(userIds)) {
      setValue('assignees', userIds, { shouldValidate: true });
      clearErrors("assignees");
    }
  }

  const removeUser = () => {
    setError("assignees", { message: "Owner is required" })
    setValue('assignees', []);
  }

  const filteredUsers = (selectedUsers: number[], users: InfoTenantUser[]) => {
    const filteredUsers = users.filter(u => selectedUsers?.some(su => su === u.id));
    return filteredUsers
  }


  return (
    <div style={{ width: "1000px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "15px" }}
      >
        <h2 style={{ margin: 0 }}>
          {defaultData ? `Edit Asset` : `Add Asset`}
        </h2>
      </Box>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: "20px",
        }}
      >
        <Box display="flex" gap={2}>
          <TextField
            label="Asset Name *"
            variant="outlined"
            fullWidth
            {...register("name", { required: "Asset Name is required" })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />

          <TextField
            label="Generic Name *"
            variant="outlined"
            fullWidth
            {...register("genericName", { required: "Generic Name is required" })}
            error={!!errors.genericName}
            helperText={errors.genericName?.message}
          />
        </Box>
        <TextField
          label="Description"
          variant="outlined"
          multiline
          rows={2}
          {...register("description")}
          error={!!errors.description}
          helperText={errors.description?.message}
        />

        <TextField
          select
          label="Status *"
          variant="outlined"
          defaultValue={defaultData?.status}
          {...register("status", { required: "Status is required" })}
          error={!!errors.status}
          helperText={errors.status?.message}
        >
          {Object.keys(assestStatus).map(key => (
            <MenuItem
              key={key}
              value={key}
            >
              <AssetStatus status={key as keyof typeof assestStatus} />
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="More Information (URL)"
          variant="outlined"
          {...register("moreInformationUrl", {
            pattern: {
              value: /^(https?:\/\/)?([\w\d\-_]+\.+[A-Za-z]{2,})+\/?.*$/,
              message: "Enter a valid URL",
            },
          })}
          error={!!errors.moreInformationUrl}
          helperText={errors.moreInformationUrl?.message}
        />
        <div>
          <div style={{ fontWeight: "bold" }}>Owner</div>
          <div style={{ marginTop: "4px", display: "flex", justifyContent: "flex-start" }}>
            {
              selectedAssignees?.length ? <UsersAvatarList users={filteredUsers(selectedAssignees || [], users || [])} removeUser={removeUser} shortView={false} /> : <UsersSelect
                allUsers={users || []}
                maxAvatars={1}
                selectedUsers={[]}
                multiple={false}
                onChange={addUser}
                showAvatar={true}
              />
            }

          </div>

          {errors.assignees && (
            <div style={{ color: "red", fontSize: "0.875rem", marginTop: "4px" }}>

              { // @ts-ignore 
                errors.assignees.message
              }
            </div>)}
        </div>

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Close
          </Button>
          {
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {defaultData ? "Update" : "Add"}
            </Button>
          }
        </Box>
      </form>
    </div>
  );
};

export default AddAssetForm;
