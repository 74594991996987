import { BaseReviewCI } from "../models/BaseReviewCI";
import { BaseSubmitReviewCI } from "../models/BaseSubmitReviewCI";
import { baseUrl, headers } from "./config";
import { fetchAndParse } from "./utils";


export function putApiSubmitReviewCI(id: number, body: BaseSubmitReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/submit-review/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiReviewedCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/ci-reviewed/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiRejectedInReviewCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/ci-rejected-in-review/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiRejectInApprovalCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/ci-rejected-in-approval/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiApprovedCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/ci-approved/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApIOnHoldCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/ci-on-hold/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
export function putApiReSubmitCI(id: number, body: BaseReviewCI, signal?: AbortSignal) {
    const url = new URL(`/api/ci-workflow/ci-resubmit/${id}`, baseUrl)
    return fetchAndParse<void>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers, signal });
}
