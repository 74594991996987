import moment from "moment";
import { BaseCI } from "../../../models/BaseCI";
import { CI } from "../../../models/CI";

export const mockEditCategoryData = (data: CI):BaseCI => {
    const editData = {
      id:data.id,
      assetId:data.asset, 
      knownCI : data.knownCI,
      category:data?.category ,
      justificationCategoryId:data.justificationCategory,
      justificationText:data.justificationText,
      ciEffectiveDate:moment(data.ciEffectiveDate).format("YYYY-MM-DD"),
      publicDate:data.publicDate ?moment(data.publicDate).format("YYYY-MM-DD"):undefined,
    approvers: data?.approvers?.map((approver) => approver.id),
    reviewers: data?.reviewers?.map((reviewer) => reviewer.id)
     };
  // @ts-ignore
    return editData;
  };