import React, {useEffect, useState, useReducer, useContext, useRef} from 'react';
import classNames from 'classnames';
import '../Panel.scss'
import '../TransformsPanel/TransformPanel.scss'
import WebViewerContext from "../../../contexts/webviewer-context";
import AnnotationFilterHelpers from "../AnnotationFilterHelpers";
import AnnotationFilter from "../AnnotationFilter";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import {HIGHLIGHT_TEXT} from "../../../constants";
import {Menu, MenuItem} from "@mui/material";
import {TransformTable} from "../TransformsPanel/transform-table";
import ReflowDropdown from "../ReflowDropdown/ReflowDropdown";
import {ReflowDropdownRef, ReflowDropdownState} from "../ReflowDropdown/withDropdown";

const ReplacedPanel = (props) => {
    const { selectedTab } = props
    const [highlightAnnotations, setHighlightAnnotations] = useState([])
    const [currentHighlights, setCurrentHighlights] = useState([]);
    const [checkedHighlights, setCheckedHighlights] = useState([]);
    const [selectedHighlights, setSelectedHighlights] = useState([]);
    const [pageRangeFilter, setPageRangeFilter] = useState(undefined);
    const [searchPhraseFilter, setSearchPhraseFilter] = useState(undefined);
    const [categoryFilter, setCategoryFilter] = useState(undefined);
    const [regexInputFilter, setRegexInputFilter] = useState(undefined);
    const [sortOrder, setSortOrder] = useState("Asc")
    const [editedAfterColumn, setEditedAfterColumn] = useState(false) //This is a flag to activate the apply button only if the user made changes to the after column.
    const [advancedOptionsAnchorEl, setAdvancedOptionsAnchorEl] = useState(null);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const { setInstance,instance } = useContext(WebViewerContext);
    let lastSelectedHighlight = useRef({})
    let currentHighlightsRef = useRef([])
    let pressedKeys=useRef([]);
    const reflowDropdownRef = useRef(null)
    const replacedTabIndex = 3

    const loadHighlights = () => {
        return instance.Core.annotationManager.getAnnotationsList()
            .filter((annotation) => annotation instanceof instance.Core.Annotations.TextHighlightAnnotation && !annotation.getCustomData('isSearchResult'));
    }

    useEffect(() => {
        const onAnnotationChanged = () => {
            const highlights = loadHighlights()
            setHighlightAnnotations(highlights)
            setSelectedHighlights(highlights.filter(highlight => highlight.highlightChecked))
        }

        const onAnnotationSelected = (annotations) => {
            if (annotations[annotations.length-1].elementName==='highlight') {
                lastSelectedHighlight.current = annotations[annotations.length - 1]
            }
            setSelectedHighlights(instance.Core.annotationManager.getSelectedAnnotations().filter((redaction) => redaction.elementName==='highlight'))
        }

        if (instance) {
            setHighlightAnnotations(loadHighlights())
            instance.Core.annotationManager.addEventListener('annotationChanged', onAnnotationChanged)
            instance.Core.annotationManager.addEventListener('annotationSelected', onAnnotationSelected);
        }
        return () => {
            instance.Core.annotationManager.removeEventListener('annotationChanged', onAnnotationChanged)
            instance.Core.annotationManager.removeEventListener('annotationSelected', onAnnotationSelected);
        }
    }, [instance]);

    useEffect(() => {
        if (!editedAfterColumn) {
            let initialValue = false
            //check to see if the replacement text(after column) have been changed previously and if so we remove the flag
            highlightAnnotations.forEach((highlight) => {
                if ((highlight.getCustomData(HIGHLIGHT_TEXT) !== highlight.getCustomData("Replacement"))) {
                    initialValue = true
                    return
                }
            })
            setEditedAfterColumn(initialValue)
        }
    }, [highlightAnnotations]);

    function arrowHandler(increment) {
        let lastSelectedHighlightIndex=currentHighlightsRef.current.indexOf(lastSelectedHighlight.current)
        if (lastSelectedHighlightIndex>-1 && lastSelectedHighlightIndex<currentHighlightsRef.current.length) {
            selectAnnotation(currentHighlightsRef.current[lastSelectedHighlightIndex+increment])
            scrollIntoViewById(currentHighlightsRef.current[lastSelectedHighlightIndex+increment].Id)
        }
    }

    function onKeyUpHandler(event) {
        pressedKeys.current=pressedKeys.current.filter((key) => key!==event.key)
    }

    function onKeyDownHandler(event) {
        if (event.key === 'Shift' || event.key === 'Control' || event.key === 'Meta') {
            pressedKeys.current.push(event.key)
        }
        if (selectedTab.current===replacedTabIndex) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
                arrowHandler(1)
            } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
                arrowHandler(-1)
            } else if (pressedKeys.current.includes('Meta') || pressedKeys.current.includes('Control')) {
                if (event.key === 'a') {
                    event.preventDefault()
                    instance.Core.annotationManager.selectAnnotations(currentHighlightsRef.current)
                } else if (event.key === 'c') {
                    checkHighlights(instance.Core.annotationManager.getSelectedAnnotations().filter((annotation) => annotation.elementName==='highlight'))
                } else if (event.key === 'u') {
                    event.preventDefault()
                    uncheckHighlights(instance.Core.annotationManager.getSelectedAnnotations().filter((annotation) => annotation.elementName==='highlight'))
                }
            }
        }
    }

    const scrollIntoViewById = (id) => {
        if (id) {
            const el = document.querySelector('#annotation-' + id);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler)
        window.addEventListener('keyup', onKeyUpHandler)
        window.oncontextmenu = () => { pressedKeys.current=[] } // when the user right click, the onKeyUpHandler doesn't get triggered
        window.onblur = () => { pressedKeys.current=[] }
        // prevent memory leak
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
            window.removeEventListener('keyup', onKeyUpHandler);
            window.oncontextmenu=null
            window.onblur=null
        }
    }, [])

    function filterPageRangeOnChange(newPageRange) {
        //We need to clone the value so that React recognizes it as a change and re-renders.
        const verifiedPageRange = newPageRange === undefined ? undefined : [...newPageRange];
        console.log('setting page range');
        setPageRangeFilter(verifiedPageRange);
    }

    function searchPhraseOnChange(newSearchPhrase) {
        console.log('setting search phrase');
        setSearchPhraseFilter(newSearchPhrase)
    }


    function regexInputOnChange(newRegexInput) {
        console.log('setting regex input');
        setRegexInputFilter(newRegexInput)
    }

    function sortChangeCallBack(newSortOrder) {
        console.log('setting order');
        setSortOrder(newSortOrder)
    }

    function categoryOnChange(newCategory) {
        console.log('setting category');
        setCategoryFilter(newCategory)
    }

    useEffect(() => {
        //We need to map these fields to the ones that are used in the filter helper methods.
        highlightAnnotations.forEach(annotation => {
            annotation.pageNum = annotation.PageNumber
        })
        AnnotationFilterHelpers.applyFiltersTransform(highlightAnnotations, searchPhraseFilter, pageRangeFilter, categoryFilter, regexInputFilter)

        const sortedHighlights = highlightAnnotations.sort((a, b) => {
            if (sortOrder==="Asc") {
                //sorting by location in document then location in page
                if (a.pageNum !== b.pageNum) {
                    return a.pageNum - b.pageNum
                } else if (a.getY() !== b.getY()) {
                    return a.getY() - b.getY()
                } else {
                    return a.getX() - b.getX()
                }
            } else {//desc
                if (b.pageNum !== a.pageNum) {
                    return b.pageNum - a.pageNum
                } else if (b.getY() !== a.getY()) {
                    return b.getY() - a.getY()
                } else {
                    return b.getX() - a.getX()
                }
            }
        })
        const filteredHighlights = [];
        sortedHighlights.forEach((annotation) => {

            if (annotation.shouldHide) {
                return
            }
            filteredHighlights.push(annotation);
        });

        setCurrentHighlights(filteredHighlights);
        currentHighlightsRef.current = filteredHighlights
    }, [highlightAnnotations, pageRangeFilter, searchPhraseFilter, categoryFilter, regexInputFilter, sortOrder]);

    const checkHighlight = (annotation) => {
        if (checkedHighlights.some(selectedHighlight => selectedHighlight === annotation)) {
            setCheckedHighlights(prevState => prevState.filter((el) => el !== annotation));
            annotation.highlightChecked=false
        } else {
            setCheckedHighlights([...checkedHighlights, annotation]);
            annotation.highlightChecked=true
        }
    }

    const onReplacementChange = (annotation, event) => {
        const value = event.target.value;
        annotation.setCustomData("Replacement", event.target.value);
        annotation.setCustomData("ManuallyEdited", value ? "true" : "");
        if (!editedAfterColumn) {
            setEditedAfterColumn(true)
        }
    }

    function selectAnnotation(annotation) {
        if (!pressedKeys.current.includes('Control') && !pressedKeys.current.includes('Meta') && !pressedKeys.current.includes('Shift')) {
            instance.Core.annotationManager.deselectAllAnnotations();
        }
        instance.Core.annotationManager.selectAnnotation(annotation);
        instance.Core.annotationManager.jumpToAnnotation(annotation);
    }

    const onChangeComplete = (annotation) => {
        instance.Core.annotationManager.trigger('annotationChanged', [annotation], 'replacementChange', {imported: false})//This trigger will notify the webviewer
        //that there have been changes to the document and hence it will keep track of those changes if the user switched tabs in the webviewer
        forceUpdate()
    }

    const renderRows = () => {
        return <TransformTable
            currentAnnotations={currentHighlights}
            selectedAnnotations={selectedHighlights}
            checkedAnnotations={checkedHighlights}
            onClickCheckbox={checkHighlight}
            selectAnnotation={selectAnnotation}
            onReplacementChange={onReplacementChange}
            onChangeComplete={onChangeComplete}
            allowEdit={false}/>
    };

    const noHighlightAnnotations = (
        <div className="no-marked-redactions">
            <div style={{display: "flex", flexDirection: "row"}}>
                <TipsAndUpdatesIcon color={"secondary"}/>
                <div className="msg" style={{marginLeft: 20}}>No replaced text to preview. Apply transformations to see changes.
                </div>
            </div>
            <WifiProtectedSetupIcon className={"transform-icon"} color={"secondary"}/>
        </div>
    );

    const redactAllButtonClassName = classNames('redact-all-marked', { disabled: highlightAnnotations.length === 0 });

    const revertCheckedHighlights = () => {
        if(reflowDropdownRef.current) {
            const reflowType = reflowDropdownRef.current.setData();
            highlightAnnotations.forEach(highlight => highlight.highlightChecked = false)
            checkedHighlights.forEach(highlight => highlight.highlightChecked = true)
            checkedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
            checkedHighlights.forEach(highlight => highlight.setCustomData("HighlightContents", highlight.getCustomData("Replacement") === "%RETAIN%" ? "%RETAIN%" : highlight.getCustomData("Replacement")))
            checkedHighlights.forEach(highlight => highlight.setCustomData("Replacement", highlight.getCustomData("Replacement") === "%RETAIN%" ? "%RETAIN%" : highlight.getContents()))
            checkedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
            if (reflowType === ReflowDropdownState.TEXT_REFLOW_DEFAULT) {
                instance.Core.annotationManager.trigger('revertText');
            } else {
                instance.Core.annotationManager.trigger('revertText-with-fit');
            }
        }
    };


    const transformCheckedHighlights = () => {
        if(reflowDropdownRef.current) {
            const reflowType = reflowDropdownRef.current.setData();
            highlightAnnotations.forEach(highlight => highlight.highlightChecked = false)
            checkedHighlights.forEach(highlight => highlight.highlightChecked = true)
            checkedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
            checkedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
            if (reflowType === ReflowDropdownState.TEXT_REFLOW_DEFAULT) {
                instance.Core.annotationManager.trigger('retransformText');
            } else {
                instance.Core.annotationManager.trigger('transformText-with-fit');
            }
        }
    };

    const changeFontCheckedHighlights = () => {
        highlightAnnotations.forEach(highlight => highlight.highlightChecked=false)
        checkedHighlights.forEach(highlight => highlight.highlightChecked=true)
        checkedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
        checkedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
        instance.Core.annotationManager.trigger('changeHighlightContentFont');
    };

    const changeCategoryCheckedHighlights = () => {
        highlightAnnotations.forEach(highlight => highlight.highlightChecked=false)
        checkedHighlights.forEach(highlight => highlight.highlightChecked=true)
        checkedHighlights.forEach(highlight => highlight.pageNum = highlight.PageNumber)
        checkedHighlights.forEach(highlight => highlight.setCustomData('trn-annot-preview', highlight.getContents()))
        instance.Core.annotationManager.trigger('replacedChangeCategory');
    };

    function checkHighlights(highlights) {
        highlights.forEach(highlight => highlight.highlightChecked=true)
        setCheckedHighlights(currentHighlightsRef.current.filter((redaction) => redaction.highlightChecked===true))
    }

    function uncheckHighlights(highlights) {
        highlights.forEach(highlight => highlight.highlightChecked=false)
        setCheckedHighlights(currentHighlightsRef.current.filter((redaction) => redaction.highlightChecked===true))
    }

    function selectAllHighlights() {
        setCheckedHighlights(currentHighlights)
        currentHighlights.forEach(highlight => highlight.highlightChecked=true)
    }

    function unselectAllHighlights() {
        setCheckedHighlights([])
        currentHighlights.forEach(highlight => highlight.highlightChecked=false)
    }

    const handleAdvanceOptionsClick = (event) => {
        setAdvancedOptionsAnchorEl(event.target);
    };

    function handleCloseAdvancedOptionsMenu() {
        setAdvancedOptionsAnchorEl(null);
    };

    return (
        <>
            <div className="d-flex justify-content-between" style={{alignItems: "center",marginBottom: '10px'}}>
            {currentHighlights.length > 0 ?
                <><div className="extra-options" style={{marginTop: 0}}>
                    <button className='Button' onClick={handleAdvanceOptionsClick}>Advanced Options
                    {advancedOptionsAnchorEl ? "  ↑" : "  ↓"}</button>
                </div>
                    <ReflowDropdown ref={reflowDropdownRef}/>
                </> : null}
            </div>
            <Menu
                id="simple-menu2"
                keepMounted
                anchorEl={advancedOptionsAnchorEl}
                open={Boolean(advancedOptionsAnchorEl)}
                onClose={handleCloseAdvancedOptionsMenu}
                style={{zIndex: 99999}}
            >
                <MenuItem disabled={checkedHighlights.length === 0} onClick={() => changeCategoryCheckedHighlights()}>Change Category</MenuItem>
                <MenuItem disabled={checkedHighlights.length === 0} onClick={() => changeFontCheckedHighlights()}>Change Font</MenuItem>
            </Menu>

            {highlightAnnotations.length > 0 ? <AnnotationFilter pageRangeChangeCallBack={filterPageRangeOnChange}
                                                                 searchPhraseChangeCallBack={searchPhraseOnChange}
                                                                 categoryChangeCallBack={categoryOnChange}
                                                                 categories={AnnotationFilterHelpers.getCategories(highlightAnnotations)}
                                                                 sortChangeCallBack={sortChangeCallBack}
                                                                 regexInputChangeCallBack={regexInputOnChange}
                                                                 limitedOptions={false} includeSelection={true}
                                                                 selectAllCallBack={selectAllHighlights} unselectAllCallBack={unselectAllHighlights} /> : null}
            {highlightAnnotations.length > 0 ? renderRows() : noHighlightAnnotations}
            {highlightAnnotations.length > 0 ? <div className="redaction-panel-controls-bottom">
                <button
                    disabled={checkedHighlights.length===0}
                    className={redactAllButtonClassName}
                    onClick={revertCheckedHighlights}
                    style={{marginRight: 5}}
                    aria-label='revert checked'
                >
                    Revert
                </button>
                <button
                    disabled={checkedHighlights.length===0 || (!editedAfterColumn)}
                    title={editedAfterColumn ? undefined : "This button is active only when you've made changes to the replaced text."}
                    className={redactAllButtonClassName}
                    onClick={transformCheckedHighlights}
                    aria-label='redact checked'
                >
                    Apply
                </button>
            </div> : null}
        </>
    );
};


export default ReplacedPanel;
