import {
  Box,
  Divider,
  Drawer,
  Typography
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { UsersAvatarList } from "../../../../../components/users-avatar-list";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux-hook";
import { useCimUserRoles } from "../../../../../hooks/useCimUserRoles";
import { CI } from "../../../../../models/CI";
import { ciWorkFlowStatusObj } from "../../../../../models/ciWorkFlowStatus";
import { InfoTenantUser } from "../../../../../models/InfoTenantUser";
import { hideProgressLine, showProgressLine } from "../../../../../redux/progress-line";
import { showSnackbar } from "../../../../../redux/snackbar";
import { usersSelector } from "../../../../../redux/users";
import { userSettingsSelector } from "../../../../../redux/userSettings";
import { getApiCIById } from "../../../../../services/ci";
import { ApproveCIAction } from "../../component/approveCIAction copy/approveCIAction";
import { ReSubmitCIAction } from "../../component/reSubmitCIAction/reSubmitCIAction";
import { ReviewCIAction } from "../../component/reviewCIAction/reviewCIAction";
import { StatusHistory } from "../../component/statusHistory";
import { SubmitCIAction } from "../../component/submitCIAction/submitCIAction";
import { WorkFlowLabel } from "../../component/workFlowLabel";
import CiDescriptionItem from "../ciDescriptionItem";
import ViewCISkeleton from "./viewSkeleton";

type ViewCIDetailDrawerProps = {
  open: boolean;
  onClose: () => void;
  ciId: number;
  onRefresh?: () => void;
};

const ViewCIDetailDrawer: React.FC<ViewCIDetailDrawerProps> = ({ open, onClose, ciId, onRefresh }) => {

  const dispatch = useAppDispatch();

  const [ciData, setCiData] = useState<CI | null>(null);
  const [loading] = useState(false);

  const userSettings = useAppSelector(userSettingsSelector);
  const users = useAppSelector((state) => usersSelector(state, false))
  const {isManager,isApprover,isReviewer} = useCimUserRoles()
  const dateFormat = userSettings?.regional?.dateFormat || "MM/DD/YYYY";

  const formatDate = (dateString?: string) => (dateString ? moment(dateString).format(dateFormat) : "-");

  const getCiData = useCallback((ciId) => {

    dispatch(showProgressLine());

    getApiCIById(ciId).then((data) => {
      setCiData(data);
      dispatch(hideProgressLine());
    }).catch((e) => {
      dispatch(showSnackbar({ message: "Error on getting CI Info", type: "info" }));
      dispatch(hideProgressLine());
    })
    dispatch(hideProgressLine());
  }, [])

  const filteredUsers = (selectedUsers: InfoTenantUser[], users: InfoTenantUser[]) => {
    const filteredUsers = users?.filter(u => selectedUsers?.some(su => su.id === u.id));
    return filteredUsers
  }

  useEffect(() => {
    getCiData(ciId)
  }, [getCiData, ciId]);

  const onReviewSuccess = () => {
    getCiData(ciId)
    if (typeof onRefresh === 'function') {
      onRefresh()
    }
  }


  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "40%",
          padding: 3,
          overflowY: "auto",
        },
      }}
    >
      {(loading || !ciData) ? (
        <Box>
          <ViewCISkeleton />
        </Box> 
    ): <Box>
        {/* Header */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            View CI
          </Typography>
          {(isManager && (ciData.status === ciWorkFlowStatusObj.DRAFT || ciData.status === ciWorkFlowStatusObj.ON_HOLD)) && <SubmitCIAction ciData={ciData} onSuccess={onReviewSuccess} />}
          {((isManager || isReviewer) && ciData.status === ciWorkFlowStatusObj.SUBMITTED_FOR_REVIEW) && <ReviewCIAction ciData={ciData} onSuccess={onReviewSuccess} />}
          {((isManager || isApprover) && ciData.status === ciWorkFlowStatusObj.IN_APPROVAL) && <ApproveCIAction ciData={ciData} onSuccess={onReviewSuccess} />}
          {(isManager && (ciData.status === ciWorkFlowStatusObj.REJECTED_FROM_REVIEW || ciData.status === ciWorkFlowStatusObj.REJECTED_FROM_APPROVAL)) && <ReSubmitCIAction ciData={ciData} onSuccess={onReviewSuccess} />}

        </Box>

        {/* Workflow Progress */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: 2, fontSize: '21px' }}>
            Workflow Progress
          </Typography>
          <WorkFlowLabel currentStatus={ciData.status} />
        </Box>

        <Divider sx={{ marginBottom: 3 }} />

        {/* CI Details */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: 2 }}>
            CI Details
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <CiDescriptionItem label="Asset" content={ciData.asset?.name || "-"} />
            <CiDescriptionItem label="Category Name" content={ciData.category?.name || "-"} />
            <CiDescriptionItem label="Justification Category" content={ciData?.justificationCategory?.name || "-"} />
            <CiDescriptionItem label="Justification Text" content={ciData.justificationText || "-"} />
            <CiDescriptionItem label="Known CI" content={ciData.knownCI || "-"} />
            <CiDescriptionItem label="Created" content={formatDate(ciData.createdAt)} />
            <CiDescriptionItem label="CI Effective Date" content={formatDate(ciData.ciEffectiveDate)} />
            <CiDescriptionItem label="Public Date" content={formatDate(ciData.publicDate)} />
            <CiDescriptionItem label="Last Modified" content={formatDate(ciData.lastModifiedAt)} />
            <CiDescriptionItem label="Owner" content={ciData.createdByName || "-"} />
            <CiDescriptionItem label="Reviewers" render={() => <UsersAvatarList users={filteredUsers(ciData.reviewers || [], users || [])} shortView={false} />} />
            <CiDescriptionItem label="Approvers" render={() => <UsersAvatarList users={filteredUsers(ciData.approvers || [], users || [])} shortView={false} />} />
          </Box>
        </Box>

        {/* Status History */}
        <Box sx={{ marginBottom: 8 }}>
          <StatusHistory history={ciData.statusHistory || []} />
        </Box>
      </Box>}
      <Box>
      </Box>
    </Drawer>
  );
};

export default ViewCIDetailDrawer;
