import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from '../models/Category';
import { RootState } from './store';

export const defaultCategories: Category[] = [];

const categoriesInitialState = {
  categories: defaultCategories,
  loaded: false,
  selected: null as number | null,
  loading: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesInitialState,
  reducers: {
    loadCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
      state.loaded = true;
    },
    loadCategory: (state, action: PayloadAction<Category>) => {
      const index = state.categories.findIndex((category) => category.id === action.payload.id);
      if (index !== -1) {
        state.categories[index] = action.payload;
      } else {
        state.categories.push(action.payload);
      }
    },
    selectCategory: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    removeCategory: (state, action: PayloadAction<number>) => {
      state.categories = state.categories.filter((category) => category.id !== action.payload);
    },
    setCategoriesLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setCategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  loadCategories,
  loadCategory,
  selectCategory,
  removeCategory,
  setCategoriesLoaded,
  setCategoriesLoading,
} = categoriesSlice.actions;

export const categoriesSelector = (state: RootState) => state.categories.categories;
export const loadedCategoriesSelector = (state: RootState) => state.categories.loaded;
export const selectedCategorySelector = (state: RootState) =>
  state.categories.categories.find((category) => category.id === state.categories.selected);

export default categoriesSlice.reducer;
